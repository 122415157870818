/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateRoleProposalRequestDto,
  GrantDto,
  JobTitleDto,
  PendingRoleUpdateApprovalRequestDto,
  PendingRoleUpdateRequestDto,
  ProposalRoleResponseDto,
  RoleResponseDto,
  UserInfoDto,
} from '../models/index';
import {
    CreateRoleProposalRequestDtoFromJSON,
    CreateRoleProposalRequestDtoToJSON,
    GrantDtoFromJSON,
    GrantDtoToJSON,
    JobTitleDtoFromJSON,
    JobTitleDtoToJSON,
    PendingRoleUpdateApprovalRequestDtoFromJSON,
    PendingRoleUpdateApprovalRequestDtoToJSON,
    PendingRoleUpdateRequestDtoFromJSON,
    PendingRoleUpdateRequestDtoToJSON,
    ProposalRoleResponseDtoFromJSON,
    ProposalRoleResponseDtoToJSON,
    RoleResponseDtoFromJSON,
    RoleResponseDtoToJSON,
    UserInfoDtoFromJSON,
    UserInfoDtoToJSON,
} from '../models/index';

export interface DeleteUserRoleRequest {
    user: string;
    role: string;
    org: string;
}

export interface ExistAcceptedOrInProgressByOrgIdAndGrantRequest {
    org: string;
    role: string;
}

export interface FindAllByOrgIdAndGrantAndAcceptedRequest {
    org: string;
    role: string;
}

export interface GetAllJobTitlesByTypeAndOrgWithStatusRequest {
    type: string;
    org: string;
    status?: string;
}

export interface GetJobTitleRequest {
    title: string;
    user: string;
    org: string;
}

export interface GetJobTitlesByUserIdAndOrgIdRequest {
    user: string;
    org: string;
}

export interface GetUserRolesByRoleAndUserIdRequest {
    role: string;
    user: string;
}

export interface GetUserRolesInfoRequest {
    role: string;
    user: string;
    org: string;
}

export interface ProposeRoleRequest {
    createRoleProposalRequestDto: CreateRoleProposalRequestDto;
}

export interface RoleProposalRequest {
    user: string;
}

export interface UpdateRoleAcceptanceRequest {
    pendingRoleUpdateRequestDto: PendingRoleUpdateRequestDto;
}

export interface UpdateRoleApprovalRequest {
    pendingRoleUpdateApprovalRequestDto: PendingRoleUpdateApprovalRequestDto;
}

export interface UserInfo1Request {
    role: string;
    org?: string;
}

export interface UserRoleCompletedRequest {
    user: string;
    role: string;
    org: string;
}

export interface UserRoleExistsRequest {
    user: string;
    role: string;
    org: string;
}

export interface UserRolesRequest {
    user: string;
    all?: boolean;
}

/**
 * 
 */
export class UserRoleControllerApi extends runtime.BaseAPI {

    /**
     */
    async deleteUserRoleRaw(requestParameters: DeleteUserRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['user'] == null) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter "user" was null or undefined when calling deleteUserRole().'
            );
        }

        if (requestParameters['role'] == null) {
            throw new runtime.RequiredError(
                'role',
                'Required parameter "role" was null or undefined when calling deleteUserRole().'
            );
        }

        if (requestParameters['org'] == null) {
            throw new runtime.RequiredError(
                'org',
                'Required parameter "org" was null or undefined when calling deleteUserRole().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['user'] != null) {
            queryParameters['user'] = requestParameters['user'];
        }

        if (requestParameters['role'] != null) {
            queryParameters['role'] = requestParameters['role'];
        }

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUserRole(requestParameters: DeleteUserRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserRoleRaw(requestParameters, initOverrides);
    }

    /**
     */
    async existAcceptedOrInProgressByOrgIdAndGrantRaw(requestParameters: ExistAcceptedOrInProgressByOrgIdAndGrantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<boolean>>> {
        if (requestParameters['org'] == null) {
            throw new runtime.RequiredError(
                'org',
                'Required parameter "org" was null or undefined when calling existAcceptedOrInProgressByOrgIdAndGrant().'
            );
        }

        if (requestParameters['role'] == null) {
            throw new runtime.RequiredError(
                'role',
                'Required parameter "role" was null or undefined when calling existAcceptedOrInProgressByOrgIdAndGrant().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        if (requestParameters['role'] != null) {
            queryParameters['role'] = requestParameters['role'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles/existence/accepted-or-in-progress`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async existAcceptedOrInProgressByOrgIdAndGrant(requestParameters: ExistAcceptedOrInProgressByOrgIdAndGrantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<boolean>> {
        const response = await this.existAcceptedOrInProgressByOrgIdAndGrantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllByOrgIdAndGrantAndAcceptedRaw(requestParameters: FindAllByOrgIdAndGrantAndAcceptedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GrantDto>>> {
        if (requestParameters['org'] == null) {
            throw new runtime.RequiredError(
                'org',
                'Required parameter "org" was null or undefined when calling findAllByOrgIdAndGrantAndAccepted().'
            );
        }

        if (requestParameters['role'] == null) {
            throw new runtime.RequiredError(
                'role',
                'Required parameter "role" was null or undefined when calling findAllByOrgIdAndGrantAndAccepted().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        if (requestParameters['role'] != null) {
            queryParameters['role'] = requestParameters['role'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles/acceptance`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GrantDtoFromJSON));
    }

    /**
     */
    async findAllByOrgIdAndGrantAndAccepted(requestParameters: FindAllByOrgIdAndGrantAndAcceptedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GrantDto>> {
        const response = await this.findAllByOrgIdAndGrantAndAcceptedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllJobTitlesByTypeAndOrgWithStatusRaw(requestParameters: GetAllJobTitlesByTypeAndOrgWithStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProposalRoleResponseDto>>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getAllJobTitlesByTypeAndOrgWithStatus().'
            );
        }

        if (requestParameters['org'] == null) {
            throw new runtime.RequiredError(
                'org',
                'Required parameter "org" was null or undefined when calling getAllJobTitlesByTypeAndOrgWithStatus().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProposalRoleResponseDtoFromJSON));
    }

    /**
     */
    async getAllJobTitlesByTypeAndOrgWithStatus(requestParameters: GetAllJobTitlesByTypeAndOrgWithStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProposalRoleResponseDto>> {
        const response = await this.getAllJobTitlesByTypeAndOrgWithStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getJobTitleRaw(requestParameters: GetJobTitleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<JobTitleDto>>> {
        if (requestParameters['title'] == null) {
            throw new runtime.RequiredError(
                'title',
                'Required parameter "title" was null or undefined when calling getJobTitle().'
            );
        }

        if (requestParameters['user'] == null) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter "user" was null or undefined when calling getJobTitle().'
            );
        }

        if (requestParameters['org'] == null) {
            throw new runtime.RequiredError(
                'org',
                'Required parameter "org" was null or undefined when calling getJobTitle().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['title'] != null) {
            queryParameters['title'] = requestParameters['title'];
        }

        if (requestParameters['user'] != null) {
            queryParameters['user'] = requestParameters['user'];
        }

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles/job-title/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(JobTitleDtoFromJSON));
    }

    /**
     */
    async getJobTitle(requestParameters: GetJobTitleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<JobTitleDto>> {
        const response = await this.getJobTitleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getJobTitlesByUserIdAndOrgIdRaw(requestParameters: GetJobTitlesByUserIdAndOrgIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<JobTitleDto>>> {
        if (requestParameters['user'] == null) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter "user" was null or undefined when calling getJobTitlesByUserIdAndOrgId().'
            );
        }

        if (requestParameters['org'] == null) {
            throw new runtime.RequiredError(
                'org',
                'Required parameter "org" was null or undefined when calling getJobTitlesByUserIdAndOrgId().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['user'] != null) {
            queryParameters['user'] = requestParameters['user'];
        }

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles/job-title/list/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(JobTitleDtoFromJSON));
    }

    /**
     */
    async getJobTitlesByUserIdAndOrgId(requestParameters: GetJobTitlesByUserIdAndOrgIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<JobTitleDto>> {
        const response = await this.getJobTitlesByUserIdAndOrgIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserRolesByRoleAndUserIdRaw(requestParameters: GetUserRolesByRoleAndUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GrantDto>>> {
        if (requestParameters['role'] == null) {
            throw new runtime.RequiredError(
                'role',
                'Required parameter "role" was null or undefined when calling getUserRolesByRoleAndUserId().'
            );
        }

        if (requestParameters['user'] == null) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter "user" was null or undefined when calling getUserRolesByRoleAndUserId().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['role'] != null) {
            queryParameters['role'] = requestParameters['role'];
        }

        if (requestParameters['user'] != null) {
            queryParameters['user'] = requestParameters['user'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles/list/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GrantDtoFromJSON));
    }

    /**
     */
    async getUserRolesByRoleAndUserId(requestParameters: GetUserRolesByRoleAndUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GrantDto>> {
        const response = await this.getUserRolesByRoleAndUserIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserRolesInfoRaw(requestParameters: GetUserRolesInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GrantDto>>> {
        if (requestParameters['role'] == null) {
            throw new runtime.RequiredError(
                'role',
                'Required parameter "role" was null or undefined when calling getUserRolesInfo().'
            );
        }

        if (requestParameters['user'] == null) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter "user" was null or undefined when calling getUserRolesInfo().'
            );
        }

        if (requestParameters['org'] == null) {
            throw new runtime.RequiredError(
                'org',
                'Required parameter "org" was null or undefined when calling getUserRolesInfo().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['role'] != null) {
            queryParameters['role'] = requestParameters['role'];
        }

        if (requestParameters['user'] != null) {
            queryParameters['user'] = requestParameters['user'];
        }

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GrantDtoFromJSON));
    }

    /**
     */
    async getUserRolesInfo(requestParameters: GetUserRolesInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GrantDto>> {
        const response = await this.getUserRolesInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async proposeRoleRaw(requestParameters: ProposeRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProposalRoleResponseDto>> {
        if (requestParameters['createRoleProposalRequestDto'] == null) {
            throw new runtime.RequiredError(
                'createRoleProposalRequestDto',
                'Required parameter "createRoleProposalRequestDto" was null or undefined when calling proposeRole().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/roles/proposal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRoleProposalRequestDtoToJSON(requestParameters['createRoleProposalRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProposalRoleResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async proposeRole(requestParameters: ProposeRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProposalRoleResponseDto> {
        const response = await this.proposeRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async roleProposalRaw(requestParameters: RoleProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProposalRoleResponseDto>>> {
        if (requestParameters['user'] == null) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter "user" was null or undefined when calling roleProposal().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['user'] != null) {
            queryParameters['user'] = requestParameters['user'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles/proposal`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProposalRoleResponseDtoFromJSON));
    }

    /**
     */
    async roleProposal(requestParameters: RoleProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProposalRoleResponseDto>> {
        const response = await this.roleProposalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateRoleAcceptanceRaw(requestParameters: UpdateRoleAcceptanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProposalRoleResponseDto>> {
        if (requestParameters['pendingRoleUpdateRequestDto'] == null) {
            throw new runtime.RequiredError(
                'pendingRoleUpdateRequestDto',
                'Required parameter "pendingRoleUpdateRequestDto" was null or undefined when calling updateRoleAcceptance().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/roles/acceptance`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PendingRoleUpdateRequestDtoToJSON(requestParameters['pendingRoleUpdateRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProposalRoleResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateRoleAcceptance(requestParameters: UpdateRoleAcceptanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProposalRoleResponseDto> {
        const response = await this.updateRoleAcceptanceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateRoleApprovalRaw(requestParameters: UpdateRoleApprovalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProposalRoleResponseDto>> {
        if (requestParameters['pendingRoleUpdateApprovalRequestDto'] == null) {
            throw new runtime.RequiredError(
                'pendingRoleUpdateApprovalRequestDto',
                'Required parameter "pendingRoleUpdateApprovalRequestDto" was null or undefined when calling updateRoleApproval().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/roles/approval`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PendingRoleUpdateApprovalRequestDtoToJSON(requestParameters['pendingRoleUpdateApprovalRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProposalRoleResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateRoleApproval(requestParameters: UpdateRoleApprovalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProposalRoleResponseDto> {
        const response = await this.updateRoleApprovalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userInfo1Raw(requestParameters: UserInfo1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserInfoDto>>> {
        if (requestParameters['role'] == null) {
            throw new runtime.RequiredError(
                'role',
                'Required parameter "role" was null or undefined when calling userInfo1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['role'] != null) {
            queryParameters['role'] = requestParameters['role'];
        }

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles/user/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserInfoDtoFromJSON));
    }

    /**
     */
    async userInfo1(requestParameters: UserInfo1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserInfoDto>> {
        const response = await this.userInfo1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return true if Grant is approved and accepted
     */
    async userRoleCompletedRaw(requestParameters: UserRoleCompletedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RoleResponseDto>>> {
        if (requestParameters['user'] == null) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter "user" was null or undefined when calling userRoleCompleted().'
            );
        }

        if (requestParameters['role'] == null) {
            throw new runtime.RequiredError(
                'role',
                'Required parameter "role" was null or undefined when calling userRoleCompleted().'
            );
        }

        if (requestParameters['org'] == null) {
            throw new runtime.RequiredError(
                'org',
                'Required parameter "org" was null or undefined when calling userRoleCompleted().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['user'] != null) {
            queryParameters['user'] = requestParameters['user'];
        }

        if (requestParameters['role'] != null) {
            queryParameters['role'] = requestParameters['role'];
        }

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles/completed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleResponseDtoFromJSON));
    }

    /**
     * Return true if Grant is approved and accepted
     */
    async userRoleCompleted(requestParameters: UserRoleCompletedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RoleResponseDto>> {
        const response = await this.userRoleCompletedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userRoleExistsRaw(requestParameters: UserRoleExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RoleResponseDto>>> {
        if (requestParameters['user'] == null) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter "user" was null or undefined when calling userRoleExists().'
            );
        }

        if (requestParameters['role'] == null) {
            throw new runtime.RequiredError(
                'role',
                'Required parameter "role" was null or undefined when calling userRoleExists().'
            );
        }

        if (requestParameters['org'] == null) {
            throw new runtime.RequiredError(
                'org',
                'Required parameter "org" was null or undefined when calling userRoleExists().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['user'] != null) {
            queryParameters['user'] = requestParameters['user'];
        }

        if (requestParameters['role'] != null) {
            queryParameters['role'] = requestParameters['role'];
        }

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles/existence`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleResponseDtoFromJSON));
    }

    /**
     */
    async userRoleExists(requestParameters: UserRoleExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RoleResponseDto>> {
        const response = await this.userRoleExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userRolesRaw(requestParameters: UserRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RoleResponseDto>>> {
        if (requestParameters['user'] == null) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter "user" was null or undefined when calling userRoles().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['user'] != null) {
            queryParameters['user'] = requestParameters['user'];
        }

        if (requestParameters['all'] != null) {
            queryParameters['all'] = requestParameters['all'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/roles/for-user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleResponseDtoFromJSON));
    }

    /**
     */
    async userRoles(requestParameters: UserRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RoleResponseDto>> {
        const response = await this.userRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
