/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddPublicRecordsRequestDto,
  OorPrerequisitesRequestDto,
  OorProposalRequestDto,
  OorProposalResponseDto,
  OorRemovalRequestDto,
  OorRemovalResponseDto,
  OorResentNotificationRequestDto,
  OorResentNotificationResponseDto,
  PossibleOorRecipientsResponseDto,
  QarOorVerificationRequestDto,
  ScheduleCeremonyRequestDto,
  UpdatePublicRecordsRequestDto,
  WorkflowExecutedResponseDto,
} from '../models/index';
import {
    AddPublicRecordsRequestDtoFromJSON,
    AddPublicRecordsRequestDtoToJSON,
    OorPrerequisitesRequestDtoFromJSON,
    OorPrerequisitesRequestDtoToJSON,
    OorProposalRequestDtoFromJSON,
    OorProposalRequestDtoToJSON,
    OorProposalResponseDtoFromJSON,
    OorProposalResponseDtoToJSON,
    OorRemovalRequestDtoFromJSON,
    OorRemovalRequestDtoToJSON,
    OorRemovalResponseDtoFromJSON,
    OorRemovalResponseDtoToJSON,
    OorResentNotificationRequestDtoFromJSON,
    OorResentNotificationRequestDtoToJSON,
    OorResentNotificationResponseDtoFromJSON,
    OorResentNotificationResponseDtoToJSON,
    PossibleOorRecipientsResponseDtoFromJSON,
    PossibleOorRecipientsResponseDtoToJSON,
    QarOorVerificationRequestDtoFromJSON,
    QarOorVerificationRequestDtoToJSON,
    ScheduleCeremonyRequestDtoFromJSON,
    ScheduleCeremonyRequestDtoToJSON,
    UpdatePublicRecordsRequestDtoFromJSON,
    UpdatePublicRecordsRequestDtoToJSON,
    WorkflowExecutedResponseDtoFromJSON,
    WorkflowExecutedResponseDtoToJSON,
} from '../models/index';

export interface AddPublicRecordsRequest {
    addPublicRecordsRequestDto: AddPublicRecordsRequestDto;
}

export interface FindCeremonyParticipantsRequest {
    workflow: string;
}

export interface OorProposalRequest {
    oorProposalRequestDto: OorProposalRequestDto;
}

export interface OorRemovalRequest {
    oorRemovalRequestDto: OorRemovalRequestDto;
}

export interface ProposedOORsRequest {
    workflow?: string;
    meeting?: string;
}

export interface QarVerifiesOorRequest {
    qarOorVerificationRequestDto: QarOorVerificationRequestDto;
}

export interface ResendNotificationToOorRecipientRequest {
    oorResentNotificationRequestDto: OorResentNotificationRequestDto;
}

export interface ScheduleCeremonyRequest {
    scheduleCeremonyRequestDto: ScheduleCeremonyRequestDto;
}

export interface StartRequest {
    oorPrerequisitesRequestDto: OorPrerequisitesRequestDto;
}

export interface UpdatePublicRecordsRequest {
    updatePublicRecordsRequestDto: UpdatePublicRecordsRequestDto;
}

/**
 * 
 */
export class OorPrerequisitesControllerApi extends runtime.BaseAPI {

    /**
     * Add public records
     */
    async addPublicRecordsRaw(requestParameters: AddPublicRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AddPublicRecordsRequestDto>> {
        if (requestParameters['addPublicRecordsRequestDto'] == null) {
            throw new runtime.RequiredError(
                'addPublicRecordsRequestDto',
                'Required parameter "addPublicRecordsRequestDto" was null or undefined when calling addPublicRecords().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/oor-prereq/additional-public-records`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddPublicRecordsRequestDtoToJSON(requestParameters['addPublicRecordsRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddPublicRecordsRequestDtoFromJSON(jsonValue));
    }

    /**
     * Add public records
     */
    async addPublicRecords(requestParameters: AddPublicRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AddPublicRecordsRequestDto> {
        const response = await this.addPublicRecordsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The list includes requester, OORs and LARs. The list does not include QARs, because they depends on the chosen timeslot
     * Return list of possible recipients.
     */
    async findCeremonyParticipantsRaw(requestParameters: FindCeremonyParticipantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PossibleOorRecipientsResponseDto>> {
        if (requestParameters['workflow'] == null) {
            throw new runtime.RequiredError(
                'workflow',
                'Required parameter "workflow" was null or undefined when calling findCeremonyParticipants().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/oor-prereq/ceremony-participants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PossibleOorRecipientsResponseDtoFromJSON(jsonValue));
    }

    /**
     * The list includes requester, OORs and LARs. The list does not include QARs, because they depends on the chosen timeslot
     * Return list of possible recipients.
     */
    async findCeremonyParticipants(requestParameters: FindCeremonyParticipantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PossibleOorRecipientsResponseDto> {
        const response = await this.findCeremonyParticipantsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Submit list of recipients
     */
    async oorProposalRaw(requestParameters: OorProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OorProposalResponseDto>> {
        if (requestParameters['oorProposalRequestDto'] == null) {
            throw new runtime.RequiredError(
                'oorProposalRequestDto',
                'Required parameter "oorProposalRequestDto" was null or undefined when calling oorProposal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/oor-prereq/oor-proposal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OorProposalRequestDtoToJSON(requestParameters['oorProposalRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OorProposalResponseDtoFromJSON(jsonValue));
    }

    /**
     * Submit list of recipients
     */
    async oorProposal(requestParameters: OorProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OorProposalResponseDto> {
        const response = await this.oorProposalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove one of the recipients
     */
    async oorRemovalRaw(requestParameters: OorRemovalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OorRemovalResponseDto>> {
        if (requestParameters['oorRemovalRequestDto'] == null) {
            throw new runtime.RequiredError(
                'oorRemovalRequestDto',
                'Required parameter "oorRemovalRequestDto" was null or undefined when calling oorRemoval().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/oor-prereq/oor-removal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OorRemovalRequestDtoToJSON(requestParameters['oorRemovalRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OorRemovalResponseDtoFromJSON(jsonValue));
    }

    /**
     * Remove one of the recipients
     */
    async oorRemoval(requestParameters: OorRemovalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OorRemovalResponseDto> {
        const response = await this.oorRemovalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return list of proposed OORs
     */
    async proposedOORsRaw(requestParameters: ProposedOORsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PossibleOorRecipientsResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        if (requestParameters['meeting'] != null) {
            queryParameters['meeting'] = requestParameters['meeting'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/oor-prereq/proposed-recipients`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PossibleOorRecipientsResponseDtoFromJSON(jsonValue));
    }

    /**
     * Return list of proposed OORs
     */
    async proposedOORs(requestParameters: ProposedOORsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PossibleOorRecipientsResponseDto> {
        const response = await this.proposedOORsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * QAR verifies OOR proposal (approve or reject)
     */
    async qarVerifiesOorRaw(requestParameters: QarVerifiesOorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QarOorVerificationRequestDto>> {
        if (requestParameters['qarOorVerificationRequestDto'] == null) {
            throw new runtime.RequiredError(
                'qarOorVerificationRequestDto',
                'Required parameter "qarOorVerificationRequestDto" was null or undefined when calling qarVerifiesOor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/oor-prereq/oor-verification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QarOorVerificationRequestDtoToJSON(requestParameters['qarOorVerificationRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QarOorVerificationRequestDtoFromJSON(jsonValue));
    }

    /**
     * QAR verifies OOR proposal (approve or reject)
     */
    async qarVerifiesOor(requestParameters: QarVerifiesOorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QarOorVerificationRequestDto> {
        const response = await this.qarVerifiesOorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resend the notification to oor recipient
     */
    async resendNotificationToOorRecipientRaw(requestParameters: ResendNotificationToOorRecipientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OorResentNotificationResponseDto>> {
        if (requestParameters['oorResentNotificationRequestDto'] == null) {
            throw new runtime.RequiredError(
                'oorResentNotificationRequestDto',
                'Required parameter "oorResentNotificationRequestDto" was null or undefined when calling resendNotificationToOorRecipient().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/oor-prereq/resent-notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OorResentNotificationRequestDtoToJSON(requestParameters['oorResentNotificationRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OorResentNotificationResponseDtoFromJSON(jsonValue));
    }

    /**
     * Resend the notification to oor recipient
     */
    async resendNotificationToOorRecipient(requestParameters: ResendNotificationToOorRecipientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OorResentNotificationResponseDto> {
        const response = await this.resendNotificationToOorRecipientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Schedule OOR vLEI issuance ceremony as per selected time slot
     */
    async scheduleCeremonyRaw(requestParameters: ScheduleCeremonyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScheduleCeremonyRequestDto>> {
        if (requestParameters['scheduleCeremonyRequestDto'] == null) {
            throw new runtime.RequiredError(
                'scheduleCeremonyRequestDto',
                'Required parameter "scheduleCeremonyRequestDto" was null or undefined when calling scheduleCeremony().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/oor-prereq/scheduling-ceremony`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ScheduleCeremonyRequestDtoToJSON(requestParameters['scheduleCeremonyRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScheduleCeremonyRequestDtoFromJSON(jsonValue));
    }

    /**
     * Schedule OOR vLEI issuance ceremony as per selected time slot
     */
    async scheduleCeremony(requestParameters: ScheduleCeremonyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScheduleCeremonyRequestDto> {
        const response = await this.scheduleCeremonyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Execute node from existing workflow execution for OOR Prerequisites
     * Create and start a new OOR Prerequisites Workflow Instance
     */
    async startRaw(requestParameters: StartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkflowExecutedResponseDto>> {
        if (requestParameters['oorPrerequisitesRequestDto'] == null) {
            throw new runtime.RequiredError(
                'oorPrerequisitesRequestDto',
                'Required parameter "oorPrerequisitesRequestDto" was null or undefined when calling start().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/oor-prereq/start`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OorPrerequisitesRequestDtoToJSON(requestParameters['oorPrerequisitesRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowExecutedResponseDtoFromJSON(jsonValue));
    }

    /**
     * Execute node from existing workflow execution for OOR Prerequisites
     * Create and start a new OOR Prerequisites Workflow Instance
     */
    async start(requestParameters: StartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkflowExecutedResponseDto> {
        const response = await this.startRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update public records
     */
    async updatePublicRecordsRaw(requestParameters: UpdatePublicRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdatePublicRecordsRequestDto>> {
        if (requestParameters['updatePublicRecordsRequestDto'] == null) {
            throw new runtime.RequiredError(
                'updatePublicRecordsRequestDto',
                'Required parameter "updatePublicRecordsRequestDto" was null or undefined when calling updatePublicRecords().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/oor-prereq/updated-public-records`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePublicRecordsRequestDtoToJSON(requestParameters['updatePublicRecordsRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdatePublicRecordsRequestDtoFromJSON(jsonValue));
    }

    /**
     * Update public records
     */
    async updatePublicRecords(requestParameters: UpdatePublicRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdatePublicRecordsRequestDto> {
        const response = await this.updatePublicRecordsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
