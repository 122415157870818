import { FormattedMessage } from 'react-intl'
import SidebarNav, { ILink } from '@/components/base/SidebarNav'
import ConnectionIcon from '@/components/icons/ConnectionIcon'
import HoldIcon from '@/components/icons/HoldIcon'
import IssueIcon from '@/components/icons/IssueIcon'
import SettingsIcon from '@/components/icons/SettingsIcon'
import VerifyIcon from '@/components/icons/VerifyIcon'

const links: ILink[] = [
  {
    href: '/',
    label: <FormattedMessage id="sideBar.link.myWork" />,
    icon: <ConnectionIcon />
  },
  {
    href: '/identifiers',
    label: <FormattedMessage id="sideBar.link.identifiers" />,
    icon: <HoldIcon />
  },
  {
    href: '/issue',
    label: <FormattedMessage id="sideBar.link.issue" />,
    icon: <IssueIcon />
  },
  {
    href: '/hold',
    label: <FormattedMessage id="sideBar.link.hold" />,
    icon: <HoldIcon />
  },
  {
    href: '/verify',
    label: <FormattedMessage id="sideBar.link.verify" />,
    icon: <VerifyIcon />
  },
  {
    href: '/connections',
    label: <FormattedMessage id="sideBar.link.connections" />,
    icon: <ConnectionIcon />
  },
  {
    href: '/settings',
    label: <FormattedMessage id="sideBar.link.settings" />,
    icon: <SettingsIcon />
  },
  {
    href: '/profile',
    label: <FormattedMessage id="sideBar.link.profile" />,
    icon: <SettingsIcon />
  },
  {
    href: '/apps',
    label: <FormattedMessage id="sideBar.link.applications" />,
    icon: <ConnectionIcon />
  }
]

const LeftSidebar = () => {
  return <SidebarNav links={links} className="fixed w-[inherit] top-[55px]" />
}
export default LeftSidebar
