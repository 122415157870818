const HelpIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_18368_4873)">
        <path
          d="M1.79964 12.693L7.39705 15.8717C7.64017 16.0112 7.91595 16.0846 8.19668 16.0846C8.47741 16.0846 8.75319 16.0112 8.99631 15.8717L14.5937 12.693C14.8374 12.5531 15.0396 12.3519 15.18 12.1096C15.3204 11.8673 15.394 11.5925 15.3934 11.3128V4.95537C15.394 4.67576 15.3204 4.40092 15.18 4.15861C15.0396 3.91629 14.8374 3.71508 14.5937 3.57527L8.99631 0.396529C8.75319 0.257033 8.47741 0.183594 8.19668 0.183594C7.91595 0.183594 7.64017 0.257033 7.39705 0.396529L1.79964 3.57527C1.55597 3.71508 1.35374 3.91629 1.21334 4.15861C1.07294 4.40092 0.999355 4.67576 1 4.95537V11.3128C0.999355 11.5925 1.07294 11.8673 1.21334 12.1096C1.35374 12.3519 1.55597 12.5531 1.79964 12.693ZM2.59927 4.95537L8.19668 1.77663L13.7941 4.95537V11.3128L8.19668 14.4916L2.59927 11.3128V4.95537Z"
          fill="#BABABA"
        />
        <path
          d="M6 7L8 5L10 7L8 9V10.5M8 12.5V11.25"
          stroke="black"
          stroke-width="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_18368_4873">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default HelpIcon
