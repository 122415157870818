import HelpIcon from '@/components/icons/HelpIcon'
import { Card } from 'flowbite-react'
import { useIntl } from 'react-intl'

interface IProps {
  showHelpOptions: boolean
  onClick: () => void
}

const HelpAndSupport = ({ showHelpOptions, onClick }: IProps) => {
  const { formatMessage } = useIntl()
  return (
    <>
      <div className="px-3">
        {showHelpOptions && (
          <div className="flex flex-col p-2 bg-white border border-gray-200 rounded-lg shadow-md dark:border-gray-700 dark:bg-gray-800">
            <a
              target="_blank"
              rel="noreferrer"
              href={formatMessage({
                id: 'app.help.documentation.link'
              })}
              className="flex items-center px-3 py-2 transition-all duration-200 rounded cursor-pointer hover:font-bold hover:bg-gray-100"
            >
              <span className="px-2">
                {formatMessage({ id: 'app.help.documentation.label' })}
              </span>
            </a>
            <a
              href={formatMessage({ id: 'app.help.email.link' })}
              className="flex items-center px-3 py-2 transition-all duration-200 rounded cursor-pointer hover:font-bold hover:bg-gray-100"
            >
              <span className="px-2">
                {formatMessage({ id: 'app.help.email.label' })}
              </span>
            </a>
          </div>
        )}

        <div
          className="flex items-center px-3 py-2 my-2 transition-all duration-200 rounded cursor-pointer hover:font-bold hover:bg-gray-100"
          onClick={onClick}
        >
          <HelpIcon />
          <span className="px-2">
            {formatMessage({ id: 'app.help.label' })}
          </span>
        </div>
      </div>
    </>
  )
}

export default HelpAndSupport
