/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SetDetailsRequestDto,
} from '../models/index';
import {
    SetDetailsRequestDtoFromJSON,
    SetDetailsRequestDtoToJSON,
} from '../models/index';

export interface SetDetailsRequest {
    setDetailsRequestDto: SetDetailsRequestDto;
    mocked?: boolean;
}

/**
 * 
 */
export class ApiTestsControllerApi extends runtime.BaseAPI {

    /**
     */
    async clearRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/id-verification/.private/user-verification-details`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async clear(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.clearRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async setDetailsRaw(requestParameters: SetDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['setDetailsRequestDto'] == null) {
            throw new runtime.RequiredError(
                'setDetailsRequestDto',
                'Required parameter "setDetailsRequestDto" was null or undefined when calling setDetails().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mocked'] != null) {
            queryParameters['mocked'] = requestParameters['mocked'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/id-verification/.private/user-verification-details`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetDetailsRequestDtoToJSON(requestParameters['setDetailsRequestDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async setDetails(requestParameters: SetDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.setDetailsRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

}
