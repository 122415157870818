enum TaskStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  ASSIGNED = 'ASSIGNED',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
  NOT_STARTED = 'NOT_STARTED',
  NOT_ASSIGNED = 'NOT_ASSIGNED',
  QAR_REJECTED = 'QAR_REJECTED',
  REJECTED = 'REJECTED',
  TERMINATED = 'TERMINATED'
}

export default TaskStatus
