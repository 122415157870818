import {
  CreateMultisigRequest,
  CreateNativeAidRequest
} from '@/api/origin-agent-svc'
import { getOobis } from '@/services/signify'
import {
  findAllNativeAidsFromAgent,
  saveIdentifierToAgent,
  saveMultisigToAgent
} from '@/services/wallet/agent'
import { getIdentifierTypeFromAlias } from '@/services/wallet/util'
import { CLIENT_CONNECTIVITY_STATUS } from '@/state/signify'
import useStore from '@/state/store'
import { useEffect, useState } from 'react'

export enum SyncStatus {
  IDLE = 'IDLE',
  SYNCING = 'SYNCING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}
const useKeriaAgentSync = () => {
  const {
    signifyClient,
    clientConnectivityStatus,
    notificationProcessingStatus,
    getAids,
    authentication: { userInfo }
  } = useStore()

  const [status, setStatus] = useState<SyncStatus>(SyncStatus.IDLE)
  const [error, setError] = useState(null)

  const sync = async () => {
    setStatus(SyncStatus.SYNCING)
    try {
      const aids = await getAids()
      const agentAids = await findAllNativeAidsFromAgent({
        userId: userInfo.id
      })

      const multisigKeriaAids = aids.filter((aid) => aid.isMultisig)
      const singleSigKeriaAids = aids.filter((aid) => !aid.isMultisig)

      for (const aid of singleSigKeriaAids) {
        const foundInAgent = agentAids.find(
          (agentAid) => agentAid.aid === aid.prefix
        )
        if (!foundInAgent) {
          const oobis = await getOobis(signifyClient, aid.name, 'agent')
          let type = getIdentifierTypeFromAlias(aid.name, userInfo.id)
          const payload: CreateNativeAidRequest = {
            aid: aid.prefix,
            alias: aid.name,
            oobi: oobis.oobis,
            userId: userInfo.id,
            isMultisig: aid.isMultisig,
            orgId: userInfo.orgs[0].id,
            type: type
          }

          try {
            await saveIdentifierToAgent(payload)
          } catch (err) {
            console.log(err)
          }
        }
      }

      for (const aid of multisigKeriaAids) {
        const foundInAgent = agentAids.find(
          (agentAid) => agentAid.aid === aid.prefix
        )

        if (!foundInAgent) {
          const oobi = await getOobis(signifyClient, aid.name, 'agent')
          const oobiMultisig = oobi.oobis[0].split('/agent/')[0]
          let type = getIdentifierTypeFromAlias(aid.name, userInfo.id)

          const payload: CreateMultisigRequest = {
            multisigAid: {
              aid: aid.prefix,
              alias: aid.name,
              oobi: [oobiMultisig],
              userId: userInfo.id,
              isMultisig: aid.isMultisig,
              orgId: userInfo.orgs[0].id,
              type: type,
              signingThreshold: aid?.raw?.state?.kt
            },
            memberAIDs: aid.group.members.map((member) => member.aid)
          }

          try {
            await saveMultisigToAgent(payload)
          } catch (err) {
            console.log(err)
          }
        }
      }

      setStatus(SyncStatus.COMPLETED)
    } catch (err) {
      setStatus(SyncStatus.FAILED)
      setError(err)
    }
  }

  useEffect(() => {
    if (
      clientConnectivityStatus !== CLIENT_CONNECTIVITY_STATUS.CONNECTED ||
      notificationProcessingStatus.length > 0 ||
      status === SyncStatus.SYNCING
    )
      return
    sync()
  }, [clientConnectivityStatus, notificationProcessingStatus])

  return { status, error }
}

export default useKeriaAgentSync
