import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button } from '@/components/base/Button'
import 'react-phone-input-2/lib/style.css'
import RecipientCard from './recipient-card'
import { useOorWorkflowStore } from '../store'
import { OorTitle, getOorTitles } from '../api'
import { toast } from 'react-toastify'
import { getErrorMessage } from '@/config/errorCodes'
import useStore from '@/state/store'
import { Checkbox, Label } from 'flowbite-react'
import TaskType from '@/enums/taskType'

interface IProps {
  isReadOnlyMode: boolean
  updateRecipients: Function
}

export const ProposeRecipients = ({
  isReadOnlyMode,
  updateRecipients
}: IProps) => {
  const { formatMessage } = useIntl()
  const { recipients, addRecipient, setRecipients, workflow } =
    useOorWorkflowStore()
  const [oorTitles, setOorTitles] = useState<OorTitle[]>([])
  const [addMeBtnClick, setAddMeBtnClick] = useState<boolean>(false)
  const isCurrentTaskCompleted: boolean = workflow
    .findTaskByType(TaskType.PROPOSE_RECIPIENTS)
    .isTaskCompleted()
  const {
    authentication: { userInfo }
  } = useStore()

  const handleAddRecipient = () => {
    if (!isLastBoxEmpty()) addRecipient()

    // Scroll to the last added representative box input with a slight delay
    setTimeout(() => {
      const lastRepresentativeBox = document.querySelector(
        '.representative-box:last-child'
      )
      if (lastRepresentativeBox) {
        lastRepresentativeBox.scrollIntoView({ behavior: 'smooth' })
      }
    }, 50)
  }

  useEffect(() => {
    let isActive = true

    const fetchTitles = async () => {
      try {
        let resp = await getOorTitles('US')
        if (isActive) {
          // todo speak with BE team to update swagger docs
          // @ts-ignore
          setOorTitles(resp)
        }
      } catch (error) {
        console.error('ERROR_FETCH_OOR_TITLES: ', error)
        toast.error(getErrorMessage(error.name))
      }
    }
    if (oorTitles) fetchTitles()

    return () => {
      isActive = false
    }
  }, [])

  const isLastBoxEmpty = (): boolean => {
    let recipient = recipients[recipients?.length - 1]
    return (
      recipient?.firstName === '' &&
      recipient?.lastName === '' &&
      recipient?.email === '' &&
      recipient?.orgName === '' &&
      recipient?.oorTitle === ''
    )
  }

  const isCurrentUserAdded = () => {
    return recipients?.some((recipient) => {
      return userInfo?.emails?.some((email) => email.value === recipient?.email)
    })
  }
  const handleAddMe = () => {
    if (!isCurrentUserAdded() && !addMeBtnClick) {
      const currentUser = {
        firstName: userInfo?.firstName,
        email: userInfo?.emails?.[0]?.value,
        lastName: userInfo?.lastName,
        oorTitle: '',
        orgName: userInfo?.orgs?.[0]?.name,
        shortTitle: '',
        userId: userInfo?.id
      }
      if (recipients?.length > 0) {
        if (isLastBoxEmpty()) {
          let arr = [...recipients]
          arr[arr.length - 1] = currentUser
          setRecipients(arr)
        } else setRecipients([...recipients, currentUser])
      } else setRecipients([currentUser])
    } else if (addMeBtnClick && isCurrentUserAdded()) {
      let newRecipient = recipients?.filter((recipient) => {
        return !userInfo?.emails?.some(
          (email) => email.value === recipient?.email
        )
      })
      if (newRecipient.length < 1) {
        const emptyRecipient = {
          firstName: '',
          email: '',
          lastName: '',
          oorTitle: '',
          orgName: '',
          shortTitle: '',
          userId: ''
        }
        newRecipient = [...newRecipient, emptyRecipient]
      }
      setRecipients(newRecipient)
    }
    setAddMeBtnClick(!addMeBtnClick)
  }

  return (
    <div className="p-4 mt-6">
      <div>
        <div className="flex items-start justify-between align-top">
          <div className="flex justify-between w-full">
            <h2 className="self-center text-lg font-medium leading-6 tracking-tight">
              {formatMessage({
                id: 'oor.task.recipient.addRecipient'
              })}
            </h2>
            <Button
              size="small"
              label={
                <FormattedMessage id="oor.task.recipient.cta.addRecipients" />
              }
              disabled={isReadOnlyMode}
              onClick={handleAddRecipient}
            />
          </div>
        </div>
        <p className="max-w-[582px] mt-3">
          <FormattedMessage id="oor.task.recipient.addRecipient.caption" />{' '}
          <a className="underline font-medium">
            <FormattedMessage id="cta.here" />
          </a>
          .
        </p>
        <div
          className="flex flex-row p-4 mt-6 border rounded-md shadow cursor-pointer bg-grey125"
          onClick={handleAddMe}
        >
          <Checkbox
            className="mt-[5px] cursor-pointer"
            defaultChecked={isCurrentUserAdded()}
            checked={isCurrentUserAdded()}
            onChange={handleAddMe}
            disabled={isCurrentTaskCompleted}
          />
          <div className="m-0 ml-2 cursor-pointer" onClick={handleAddMe}>
            <Label
              className="cursor-pointer text-royalBlue"
              onClick={handleAddMe}
            >
              <FormattedMessage id="oor.recipient.addMyself" />
            </Label>
            <br />
            <Label
              className="!text-gray-500 !text-[0.75rem] !leading-[0.25rem] cursor-pointer"
              onClick={handleAddMe}
            >
              <FormattedMessage id="ecr.task.recipient.addRecipient.checkbox.note" />
            </Label>
          </div>
        </div>
      </div>
      {recipients?.map((recipient, index) => (
        <RecipientCard
          recipient={recipient}
          index={index}
          key={index}
          isReadOnlyMode={isReadOnlyMode}
          updateRecipients={updateRecipients}
          oorTitles={oorTitles}
        />
      ))}
    </div>
  )
}
