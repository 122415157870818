/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ServiceProviderDto,
  ServiceProviderRequest,
} from '../models/index';
import {
    ServiceProviderDtoFromJSON,
    ServiceProviderDtoToJSON,
    ServiceProviderRequestFromJSON,
    ServiceProviderRequestToJSON,
} from '../models/index';

export interface FindAllByServiceTypeRequest {
    serviceType: string;
}

export interface FindByIdRequest {
    serviceProviderId: string;
}

export interface FindByOrgIdRequest {
    orgId: string;
}

export interface FindByOrgIdAndServiceTypeRequest {
    orgId: string;
    serviceType: string;
}

export interface FindServiceProvidersForClientRequest {
    clientOrgId: string;
    serviceType: string;
}

export interface MostRelevantServiceProviderRequest {
    clientOrg: string;
    serviceType: string;
}

export interface NewServiceProviderRequest {
    serviceProviderRequest: ServiceProviderRequest;
}

/**
 * 
 */
export class ServiceProviderControllerApi extends runtime.BaseAPI {

    /**
     * Find all service providers for specified serviceType
     */
    async findAllByServiceTypeRaw(requestParameters: FindAllByServiceTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['serviceType'] == null) {
            throw new runtime.RequiredError(
                'serviceType',
                'Required parameter "serviceType" was null or undefined when calling findAllByServiceType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/service-providers/service-type/{service-type}`.replace(`{${"service-type"}}`, encodeURIComponent(String(requestParameters['serviceType']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Find all service providers for specified serviceType
     */
    async findAllByServiceType(requestParameters: FindAllByServiceTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.findAllByServiceTypeRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Retrieves all info about service provider and its org
     * Find service provider by id
     */
    async findByIdRaw(requestParameters: FindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceProviderDto>> {
        if (requestParameters['serviceProviderId'] == null) {
            throw new runtime.RequiredError(
                'serviceProviderId',
                'Required parameter "serviceProviderId" was null or undefined when calling findById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/service-providers/{service-provider-id}`.replace(`{${"service-provider-id"}}`, encodeURIComponent(String(requestParameters['serviceProviderId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceProviderDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves all info about service provider and its org
     * Find service provider by id
     */
    async findById(requestParameters: FindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceProviderDto | null | undefined > {
        const response = await this.findByIdRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Find all service providers by service provider\'s orgId
     */
    async findByOrgIdRaw(requestParameters: FindByOrgIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['orgId'] == null) {
            throw new runtime.RequiredError(
                'orgId',
                'Required parameter "orgId" was null or undefined when calling findByOrgId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/service-providers/org/{org-id}`.replace(`{${"org-id"}}`, encodeURIComponent(String(requestParameters['orgId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Find all service providers by service provider\'s orgId
     */
    async findByOrgId(requestParameters: FindByOrgIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.findByOrgIdRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Find service provider by service provider\'s orgId and serviceType
     */
    async findByOrgIdAndServiceTypeRaw(requestParameters: FindByOrgIdAndServiceTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceProviderDto>> {
        if (requestParameters['orgId'] == null) {
            throw new runtime.RequiredError(
                'orgId',
                'Required parameter "orgId" was null or undefined when calling findByOrgIdAndServiceType().'
            );
        }

        if (requestParameters['serviceType'] == null) {
            throw new runtime.RequiredError(
                'serviceType',
                'Required parameter "serviceType" was null or undefined when calling findByOrgIdAndServiceType().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['serviceType'] != null) {
            queryParameters['service-type'] = requestParameters['serviceType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/service-providers/by-org-id/{org-id}`.replace(`{${"org-id"}}`, encodeURIComponent(String(requestParameters['orgId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceProviderDtoFromJSON(jsonValue));
    }

    /**
     * Find service provider by service provider\'s orgId and serviceType
     */
    async findByOrgIdAndServiceType(requestParameters: FindByOrgIdAndServiceTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceProviderDto | null | undefined > {
        const response = await this.findByOrgIdAndServiceTypeRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Find service providers for client by serviceType and client\'s orgId
     */
    async findServiceProvidersForClientRaw(requestParameters: FindServiceProvidersForClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['clientOrgId'] == null) {
            throw new runtime.RequiredError(
                'clientOrgId',
                'Required parameter "clientOrgId" was null or undefined when calling findServiceProvidersForClient().'
            );
        }

        if (requestParameters['serviceType'] == null) {
            throw new runtime.RequiredError(
                'serviceType',
                'Required parameter "serviceType" was null or undefined when calling findServiceProvidersForClient().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['serviceType'] != null) {
            queryParameters['service-type'] = requestParameters['serviceType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/service-providers/by-client-org-id/{client-org-id}`.replace(`{${"client-org-id"}}`, encodeURIComponent(String(requestParameters['clientOrgId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Find service providers for client by serviceType and client\'s orgId
     */
    async findServiceProvidersForClient(requestParameters: FindServiceProvidersForClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.findServiceProvidersForClientRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Find the service provider by client\'s orgId and serviceType. If service provider is not present, this endpoint research the DB for the most valuable service provider.
     */
    async mostRelevantServiceProviderRaw(requestParameters: MostRelevantServiceProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['clientOrg'] == null) {
            throw new runtime.RequiredError(
                'clientOrg',
                'Required parameter "clientOrg" was null or undefined when calling mostRelevantServiceProvider().'
            );
        }

        if (requestParameters['serviceType'] == null) {
            throw new runtime.RequiredError(
                'serviceType',
                'Required parameter "serviceType" was null or undefined when calling mostRelevantServiceProvider().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['clientOrg'] != null) {
            queryParameters['client-org'] = requestParameters['clientOrg'];
        }

        if (requestParameters['serviceType'] != null) {
            queryParameters['service-type'] = requestParameters['serviceType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/service-providers/most-relevant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Find the service provider by client\'s orgId and serviceType. If service provider is not present, this endpoint research the DB for the most valuable service provider.
     */
    async mostRelevantServiceProvider(requestParameters: MostRelevantServiceProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.mostRelevantServiceProviderRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Creates a new service provider, using the request body (JSON object). Returns service provider\'s id
     */
    async newServiceProviderRaw(requestParameters: NewServiceProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['serviceProviderRequest'] == null) {
            throw new runtime.RequiredError(
                'serviceProviderRequest',
                'Required parameter "serviceProviderRequest" was null or undefined when calling newServiceProvider().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/org/service-providers/new`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceProviderRequestToJSON(requestParameters['serviceProviderRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Creates a new service provider, using the request body (JSON object). Returns service provider\'s id
     */
    async newServiceProvider(requestParameters: NewServiceProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.newServiceProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
