/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  L10nBatchRequestDto,
  L10nTextBatchResponseDto,
  L10nTextResponseDto,
  ListOfLocalizedTextsResponseDto,
} from '../models/index';
import {
    L10nBatchRequestDtoFromJSON,
    L10nBatchRequestDtoToJSON,
    L10nTextBatchResponseDtoFromJSON,
    L10nTextBatchResponseDtoToJSON,
    L10nTextResponseDtoFromJSON,
    L10nTextResponseDtoToJSON,
    ListOfLocalizedTextsResponseDtoFromJSON,
    ListOfLocalizedTextsResponseDtoToJSON,
} from '../models/index';

export interface DeleteLocalizedTextRequest {
    symbolicTextId: string;
    lang: string;
}

export interface FindListOfTextsRequest {
    lang: string;
}

export interface GetLocalizedTextRequest {
    symbolicTextId: string;
    lang: string;
}

export interface GetLocalizedTextBatchForUserRequest {
    user: string;
    l10nBatchRequestDto: L10nBatchRequestDto;
}

export interface GetLocalizedTextForUserRequest {
    symbolicTextId: string;
    user: string;
}

export interface SaveLocalizedTextRequest {
    symbolicTextId: string;
    lang: string;
    body: string;
}

/**
 * 
 */
export class L10nControllerApi extends runtime.BaseAPI {

    /**
     */
    async deleteLocalizedTextRaw(requestParameters: DeleteLocalizedTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['symbolicTextId'] == null) {
            throw new runtime.RequiredError(
                'symbolicTextId',
                'Required parameter "symbolicTextId" was null or undefined when calling deleteLocalizedText().'
            );
        }

        if (requestParameters['lang'] == null) {
            throw new runtime.RequiredError(
                'lang',
                'Required parameter "lang" was null or undefined when calling deleteLocalizedText().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['symbolicTextId'] != null) {
            queryParameters['symbolic-text-id'] = requestParameters['symbolicTextId'];
        }

        if (requestParameters['lang'] != null) {
            queryParameters['lang'] = requestParameters['lang'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/l10n/localized-text`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deleteLocalizedText(requestParameters: DeleteLocalizedTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteLocalizedTextRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get localized text for the list of symbolicTextIds and a lang
     */
    async findListOfTextsRaw(requestParameters: FindListOfTextsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListOfLocalizedTextsResponseDto>> {
        if (requestParameters['lang'] == null) {
            throw new runtime.RequiredError(
                'lang',
                'Required parameter "lang" was null or undefined when calling findListOfTexts().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['lang'] != null) {
            queryParameters['lang'] = requestParameters['lang'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/l10n/localized-text/ui`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListOfLocalizedTextsResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get localized text for the list of symbolicTextIds and a lang
     */
    async findListOfTexts(requestParameters: FindListOfTextsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListOfLocalizedTextsResponseDto> {
        const response = await this.findListOfTextsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get localized text by symbolicTextId and its lang
     */
    async getLocalizedTextRaw(requestParameters: GetLocalizedTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<L10nTextResponseDto>> {
        if (requestParameters['symbolicTextId'] == null) {
            throw new runtime.RequiredError(
                'symbolicTextId',
                'Required parameter "symbolicTextId" was null or undefined when calling getLocalizedText().'
            );
        }

        if (requestParameters['lang'] == null) {
            throw new runtime.RequiredError(
                'lang',
                'Required parameter "lang" was null or undefined when calling getLocalizedText().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['symbolicTextId'] != null) {
            queryParameters['symbolic-text-id'] = requestParameters['symbolicTextId'];
        }

        if (requestParameters['lang'] != null) {
            queryParameters['lang'] = requestParameters['lang'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/l10n/localized-text`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => L10nTextResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get localized text by symbolicTextId and its lang
     */
    async getLocalizedText(requestParameters: GetLocalizedTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<L10nTextResponseDto> {
        const response = await this.getLocalizedTextRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all localized texts by symbolicTextIds and their lang
     */
    async getLocalizedTextBatchForUserRaw(requestParameters: GetLocalizedTextBatchForUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<L10nTextBatchResponseDto>> {
        if (requestParameters['user'] == null) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter "user" was null or undefined when calling getLocalizedTextBatchForUser().'
            );
        }

        if (requestParameters['l10nBatchRequestDto'] == null) {
            throw new runtime.RequiredError(
                'l10nBatchRequestDto',
                'Required parameter "l10nBatchRequestDto" was null or undefined when calling getLocalizedTextBatchForUser().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['user'] != null) {
            queryParameters['user'] = requestParameters['user'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/l10n/localized-text/user/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: L10nBatchRequestDtoToJSON(requestParameters['l10nBatchRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => L10nTextBatchResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get all localized texts by symbolicTextIds and their lang
     */
    async getLocalizedTextBatchForUser(requestParameters: GetLocalizedTextBatchForUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<L10nTextBatchResponseDto> {
        const response = await this.getLocalizedTextBatchForUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get localized text by symbolicTextId and its lang
     */
    async getLocalizedTextForUserRaw(requestParameters: GetLocalizedTextForUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<L10nTextResponseDto>> {
        if (requestParameters['symbolicTextId'] == null) {
            throw new runtime.RequiredError(
                'symbolicTextId',
                'Required parameter "symbolicTextId" was null or undefined when calling getLocalizedTextForUser().'
            );
        }

        if (requestParameters['user'] == null) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter "user" was null or undefined when calling getLocalizedTextForUser().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['symbolicTextId'] != null) {
            queryParameters['symbolic-text-id'] = requestParameters['symbolicTextId'];
        }

        if (requestParameters['user'] != null) {
            queryParameters['user'] = requestParameters['user'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/l10n/localized-text/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => L10nTextResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get localized text by symbolicTextId and its lang
     */
    async getLocalizedTextForUser(requestParameters: GetLocalizedTextForUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<L10nTextResponseDto> {
        const response = await this.getLocalizedTextForUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveLocalizedTextRaw(requestParameters: SaveLocalizedTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['symbolicTextId'] == null) {
            throw new runtime.RequiredError(
                'symbolicTextId',
                'Required parameter "symbolicTextId" was null or undefined when calling saveLocalizedText().'
            );
        }

        if (requestParameters['lang'] == null) {
            throw new runtime.RequiredError(
                'lang',
                'Required parameter "lang" was null or undefined when calling saveLocalizedText().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling saveLocalizedText().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['symbolicTextId'] != null) {
            queryParameters['symbolic-text-id'] = requestParameters['symbolicTextId'];
        }

        if (requestParameters['lang'] != null) {
            queryParameters['lang'] = requestParameters['lang'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/l10n/localized-text`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async saveLocalizedText(requestParameters: SaveLocalizedTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.saveLocalizedTextRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
