/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { TimeSlotDto } from './TimeSlotDto'
import {
  TimeSlotDtoFromJSON,
  TimeSlotDtoFromJSONTyped,
  TimeSlotDtoToJSON
} from './TimeSlotDto'

/**
 *
 * @export
 * @interface ScheduleCeremonyRequestDto
 */
export interface ScheduleCeremonyRequestDto {
  /**
   *
   * @type {string}
   * @memberof ScheduleCeremonyRequestDto
   */
  workflowInstanceId: string
  /**
   *
   * @type {string}
   * @memberof ScheduleCeremonyRequestDto
   */
  workflowInstanceAssignedTaskId: string
  /**
   *
   * @type {string}
   * @memberof ScheduleCeremonyRequestDto
   */
  orgId: string
  /**
   *
   * @type {TimeSlotDto}
   * @memberof ScheduleCeremonyRequestDto
   */
  timeSlot: TimeSlotDto
  /**
   *
   * @type {Array<string>}
   * @memberof ScheduleCeremonyRequestDto
   */
  lars: Array<string>
}

/**
 * Check if a given object implements the ScheduleCeremonyRequestDto interface.
 */
export function instanceOfScheduleCeremonyRequestDto(value: object): boolean {
  if (!('workflowInstanceId' in value)) return false
  if (!('workflowInstanceAssignedTaskId' in value)) return false
  if (!('orgId' in value)) return false
  if (!('timeSlot' in value)) return false
  if (!('lars' in value)) return false
  return true
}

export function ScheduleCeremonyRequestDtoFromJSON(
  json: any
): ScheduleCeremonyRequestDto {
  return ScheduleCeremonyRequestDtoFromJSONTyped(json, false)
}

export function ScheduleCeremonyRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ScheduleCeremonyRequestDto {
  if (json == null) {
    return json
  }
  return {
    workflowInstanceId: json['workflowInstanceId'],
    workflowInstanceAssignedTaskId: json['workflowInstanceAssignedTaskId'],
    orgId: json['orgId'],
    timeSlot: TimeSlotDtoFromJSON(json['timeSlot']),
    lars: json['lars']
  }
}

export function ScheduleCeremonyRequestDtoToJSON(
  value?: ScheduleCeremonyRequestDto | null
): any {
  if (value == null) {
    return value
  }
  return {
    workflowInstanceId: value['workflowInstanceId'],
    workflowInstanceAssignedTaskId: value['workflowInstanceAssignedTaskId'],
    orgId: value['orgId'],
    timeSlot: TimeSlotDtoToJSON(value['timeSlot']),
    lars: value['lars']
  }
}
