import { Modal } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import Draggable from 'react-draggable'

interface IProps {
  show?: boolean
  size?:
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | '7xl'
  position?:
    | 'center'
    | 'top-left'
    | 'top-center'
    | 'top-right'
    | 'center-left'
    | 'center-right'
    | 'bottom-right'
    | 'bottom-center'
    | 'bottom-left'
  header: React.ReactNode
  footer?: React.ReactNode
  children?: React.ReactNode
  onClose?: () => void
  className?: string
  draggable?: boolean
  headerClassName?: string
}

const ModalComponent = ({
  children,
  header,
  footer,
  show = false,
  size = 'md',
  position = 'center',
  className = '',
  headerClassName = '',
  onClose,
  draggable = false
}: IProps) => {
  const [isBrowser, setIsBrowser] = useState(false)
  const y = (Math.floor(window.innerHeight / 2) - 55) * -1

  useEffect(() => {
    setIsBrowser(typeof window !== 'undefined')
  }, [])

  const getModalContent = (isDraggable: boolean = false) => {
    return (
      <>
        <Modal.Header
          className={`m-0 ${headerClassName} ${
            isDraggable && `modal-header cursor-move`
          }`}
        >
          {header}
        </Modal.Header>
        <Modal.Body className="bg-white rounded max-h-[78vh] overflow-y-auto">
          {children}
        </Modal.Body>
        {footer && <Modal.Body className="border-t">{footer}</Modal.Body>}
      </>
    )
  }

  return isBrowser ? (
    <Modal
      show={show}
      size={size}
      position={position}
      onClose={onClose}
      className={`${className}`}
    >
      {!draggable ? (
        getModalContent()
      ) : (
        <div className="relative">
          <Draggable handle=".modal-header" defaultPosition={{ x: 0, y }}>
            <div className="absolute w-full bg-white rounded-lg">
              {getModalContent(true)}
            </div>
          </Draggable>
        </div>
      )}
    </Modal>
  ) : (
    <></>
  )
}
export default ModalComponent
