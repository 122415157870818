import { uiNotificationService } from '@/utils/apis'

export async function getUserNotifications(
  userId: string,
  isRead: boolean = false
) {
  return await uiNotificationService.getUINotificationsByUserIdAndIsRead({
    userId,
    isRead
  })
  // return await get(`/ui-notification/user/${userId}?is_read=${isRead}`, {
  //   BASE_URL: constants.NOTIFICATION_URL
  // })
}
