/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SuccessResponseDTOObject
 */
export interface SuccessResponseDTOObject {
    /**
     * 
     * @type {boolean}
     * @memberof SuccessResponseDTOObject
     */
    status?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SuccessResponseDTOObject
     */
    timeStamp?: string;
    /**
     * 
     * @type {string}
     * @memberof SuccessResponseDTOObject
     */
    message?: string;
    /**
     * 
     * @type {object}
     * @memberof SuccessResponseDTOObject
     */
    data?: object;
}

/**
 * Check if a given object implements the SuccessResponseDTOObject interface.
 */
export function instanceOfSuccessResponseDTOObject(value: object): boolean {
    return true;
}

export function SuccessResponseDTOObjectFromJSON(json: any): SuccessResponseDTOObject {
    return SuccessResponseDTOObjectFromJSONTyped(json, false);
}

export function SuccessResponseDTOObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuccessResponseDTOObject {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'] == null ? undefined : json['status'],
        'timeStamp': json['timeStamp'] == null ? undefined : json['timeStamp'],
        'message': json['message'] == null ? undefined : json['message'],
        'data': json['data'] == null ? undefined : json['data'],
    };
}

export function SuccessResponseDTOObjectToJSON(value?: SuccessResponseDTOObject | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': value['status'],
        'timeStamp': value['timeStamp'],
        'message': value['message'],
        'data': value['data'],
    };
}

