/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConfirmPassword,
  ConfirmSignup,
  JoinOrganizationConfirm,
  JoinOrganizationLookup,
  NewPersonaDto,
  Onboard,
  SetPassword,
  SetProfile,
  SuccessResponseDTOObject,
  Wallet,
} from '../models/index';
import {
    ConfirmPasswordFromJSON,
    ConfirmPasswordToJSON,
    ConfirmSignupFromJSON,
    ConfirmSignupToJSON,
    JoinOrganizationConfirmFromJSON,
    JoinOrganizationConfirmToJSON,
    JoinOrganizationLookupFromJSON,
    JoinOrganizationLookupToJSON,
    NewPersonaDtoFromJSON,
    NewPersonaDtoToJSON,
    OnboardFromJSON,
    OnboardToJSON,
    SetPasswordFromJSON,
    SetPasswordToJSON,
    SetProfileFromJSON,
    SetProfileToJSON,
    SuccessResponseDTOObjectFromJSON,
    SuccessResponseDTOObjectToJSON,
    WalletFromJSON,
    WalletToJSON,
} from '../models/index';

export interface ConfirmRequest {
    confirmSignup: ConfirmSignup;
}

export interface ConfirmPasswordRequest {
    confirmPassword: ConfirmPassword;
}

export interface JoinConfirmRequest {
    joinOrganizationConfirm: JoinOrganizationConfirm;
}

export interface JoinOrganizationRequest {
    joinOrganizationLookup: JoinOrganizationLookup;
}

export interface NewPersonaRequest {
    newPersonaDto: NewPersonaDto;
}

export interface OnboardRequest {
    onboard: Onboard;
}

export interface ResendConfirmationCodeRequest {
    requestBody: { [key: string]: string; };
}

export interface SaveWalletRequest {
    wallet: Wallet;
}

export interface SendResetPasswordLinkRequest {
    joinOrganizationLookup: JoinOrganizationLookup;
}

export interface SetPasswordRequest {
    setPassword: SetPassword;
}

export interface SetProfileRequest {
    setProfile: SetProfile;
}

/**
 * 
 */
export class OnboardControllerApi extends runtime.BaseAPI {

    /**
     */
    async confirmRaw(requestParameters: ConfirmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponseDTOObject>> {
        if (requestParameters['confirmSignup'] == null) {
            throw new runtime.RequiredError(
                'confirmSignup',
                'Required parameter "confirmSignup" was null or undefined when calling confirm().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/auth/confirm`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmSignupToJSON(requestParameters['confirmSignup']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseDTOObjectFromJSON(jsonValue));
    }

    /**
     */
    async confirm(requestParameters: ConfirmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponseDTOObject> {
        const response = await this.confirmRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async confirmPasswordRaw(requestParameters: ConfirmPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponseDTOObject>> {
        if (requestParameters['confirmPassword'] == null) {
            throw new runtime.RequiredError(
                'confirmPassword',
                'Required parameter "confirmPassword" was null or undefined when calling confirmPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/auth/password-confirmation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmPasswordToJSON(requestParameters['confirmPassword']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseDTOObjectFromJSON(jsonValue));
    }

    /**
     */
    async confirmPassword(requestParameters: ConfirmPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponseDTOObject> {
        const response = await this.confirmPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async joinConfirmRaw(requestParameters: JoinConfirmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponseDTOObject>> {
        if (requestParameters['joinOrganizationConfirm'] == null) {
            throw new runtime.RequiredError(
                'joinOrganizationConfirm',
                'Required parameter "joinOrganizationConfirm" was null or undefined when calling joinConfirm().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/auth/join-confirmation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JoinOrganizationConfirmToJSON(requestParameters['joinOrganizationConfirm']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseDTOObjectFromJSON(jsonValue));
    }

    /**
     */
    async joinConfirm(requestParameters: JoinConfirmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponseDTOObject> {
        const response = await this.joinConfirmRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async joinOrganizationRaw(requestParameters: JoinOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponseDTOObject>> {
        if (requestParameters['joinOrganizationLookup'] == null) {
            throw new runtime.RequiredError(
                'joinOrganizationLookup',
                'Required parameter "joinOrganizationLookup" was null or undefined when calling joinOrganization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/auth/join`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JoinOrganizationLookupToJSON(requestParameters['joinOrganizationLookup']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseDTOObjectFromJSON(jsonValue));
    }

    /**
     */
    async joinOrganization(requestParameters: JoinOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponseDTOObject> {
        const response = await this.joinOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async newPersonaRaw(requestParameters: NewPersonaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['newPersonaDto'] == null) {
            throw new runtime.RequiredError(
                'newPersonaDto',
                'Required parameter "newPersonaDto" was null or undefined when calling newPersona().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/auth/new-persona`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewPersonaDtoToJSON(requestParameters['newPersonaDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async newPersona(requestParameters: NewPersonaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.newPersonaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async onboardRaw(requestParameters: OnboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponseDTOObject>> {
        if (requestParameters['onboard'] == null) {
            throw new runtime.RequiredError(
                'onboard',
                'Required parameter "onboard" was null or undefined when calling onboard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/auth/onboard`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OnboardToJSON(requestParameters['onboard']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseDTOObjectFromJSON(jsonValue));
    }

    /**
     */
    async onboard(requestParameters: OnboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponseDTOObject> {
        const response = await this.onboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async resendConfirmationCodeRaw(requestParameters: ResendConfirmationCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponseDTOObject>> {
        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling resendConfirmationCode().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/auth/resend-confirmation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseDTOObjectFromJSON(jsonValue));
    }

    /**
     */
    async resendConfirmationCode(requestParameters: ResendConfirmationCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponseDTOObject> {
        const response = await this.resendConfirmationCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveWalletRaw(requestParameters: SaveWalletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponseDTOObject>> {
        if (requestParameters['wallet'] == null) {
            throw new runtime.RequiredError(
                'wallet',
                'Required parameter "wallet" was null or undefined when calling saveWallet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/auth/wallet`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WalletToJSON(requestParameters['wallet']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseDTOObjectFromJSON(jsonValue));
    }

    /**
     */
    async saveWallet(requestParameters: SaveWalletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponseDTOObject> {
        const response = await this.saveWalletRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sendResetPasswordLinkRaw(requestParameters: SendResetPasswordLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponseDTOObject>> {
        if (requestParameters['joinOrganizationLookup'] == null) {
            throw new runtime.RequiredError(
                'joinOrganizationLookup',
                'Required parameter "joinOrganizationLookup" was null or undefined when calling sendResetPasswordLink().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/auth/password-reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JoinOrganizationLookupToJSON(requestParameters['joinOrganizationLookup']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseDTOObjectFromJSON(jsonValue));
    }

    /**
     */
    async sendResetPasswordLink(requestParameters: SendResetPasswordLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponseDTOObject> {
        const response = await this.sendResetPasswordLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setPasswordRaw(requestParameters: SetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponseDTOObject>> {
        if (requestParameters['setPassword'] == null) {
            throw new runtime.RequiredError(
                'setPassword',
                'Required parameter "setPassword" was null or undefined when calling setPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/auth/password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPasswordToJSON(requestParameters['setPassword']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseDTOObjectFromJSON(jsonValue));
    }

    /**
     */
    async setPassword(requestParameters: SetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponseDTOObject> {
        const response = await this.setPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setProfileRaw(requestParameters: SetProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponseDTOObject>> {
        if (requestParameters['setProfile'] == null) {
            throw new runtime.RequiredError(
                'setProfile',
                'Required parameter "setProfile" was null or undefined when calling setProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/auth/profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetProfileToJSON(requestParameters['setProfile']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseDTOObjectFromJSON(jsonValue));
    }

    /**
     */
    async setProfile(requestParameters: SetProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponseDTOObject> {
        const response = await this.setProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
