import { CesrNumber, RotateIdentifierArgs, SignifyClient } from 'signify-ts'
import { isEstEvent, waitOperationWithRetries } from './util'
import { GroupAid, aid } from '@/state/signify'
import {
  getFirstWitnessFromEnds,
  getLocalMemberFromGroupAid,
  isGroupAid
} from '@/utils/aid'
import { getGroupMembersSigningThreshold } from './multisig-aid'
import { formatUrlPath } from '@/utils/common'
import { KELEvent } from './types'
import { consoleLog } from '@/utils/console-logger'
import { retryIf, RetryOptions } from '@/utils/retry'

/**
 * List of managed identifiers
 * @async
 * @param {number} [start=0] Start index of list of identifiers, defaults to 0
 * @param {number} [end=50] End index of list of identifiers, defaults to 50
 * @returns {Promise<any>} A promise to the list of managed identifiers
 */
export const getIdentifiers = async (
  client: SignifyClient,
  start: number = 0,
  end: number = 50
): Promise<aid[]> => {
  let result = await client.identifiers().list(start, end)
  let aids = await parseIdentifierDtoList(client, result.aids)
  return aids
}

/**
 * Get identifier detail by alias
 * @async
 * @param {string} name Name or alias of the identifier
 * @returns {Promise<any>} A promise to the identifier information
 */
export const getIdentifier = async (
  client: SignifyClient,
  alias: string,
  requiresRawData: boolean = false
): Promise<aid> => {
  let aid = await client.identifiers().get(alias)
  const identifier = await parseIdentifierDto(client, aid, requiresRawData)
  return identifier
}

/**
 * Retrieve key events for an identifier
 * @async
 * @param {string} aid Identifier/AID prefix
 * @returns {Promise<any>} A promise to the key events
 */
export const getKeyEvents = async (
  client: SignifyClient,
  aid: string
): Promise<any> => {
  return await client.keyEvents().get(aid)
}

/**
 * Retrieve the key state for an identifier
 * @async
 * @param {string} aid Identifier prefix
 * @returns {Promise<any>} A promise to the key states
 */
export const getKeyState = async (
  client: SignifyClient,
  aid: string
): Promise<any> => {
  return await client.keyStates().get(aid)
}

/**
 * Query the key state of an identifier for a given sequence number or anchor SAID
 * @async
 * @param {string} aid Identifier prefix
 * @param {string} [sn] Optional sequence number
 * @param {string} [anchor] Optional anchor SAID
 * @returns {Promise<any>} A promise to the long-running operation
 */
export const queryKeyState = async (
  client: SignifyClient,
  aid: string,
  sn?: string,
  anchor?: string
): Promise<any> => {
  return await client.keyStates().query(aid, sn, anchor)
}

/**
 * Retrieve the key states for a list of identifiers
 * @async
 * @param {Array<string>} aids List of identifier prefixes
 * @returns {Promise<any>} A promise to the key states
 */
export const getKeyStates = async (
  client: SignifyClient,
  aids: string[]
): Promise<any> => {
  return await client.keyStates().list(aids)
}

/**
 * Find last establishment event from the list of events
 * @async
 * @param {Array<any>} events List of events
 * @returns {Promise<any>} Last Est event or undefined
 */
export const findLastEstEvent = (events: any[]): any | undefined => {
  return events.reduce((lastEstEvent, currentEvent) => {
    if (!isEstEvent(currentEvent.t)) {
      return lastEstEvent
    }

    const currentSn = parseInt(currentEvent.s, 10)
    const lastSn = lastEstEvent ? parseInt(lastEstEvent.s, 10) : -Infinity

    return currentSn > lastSn ? currentEvent : lastEstEvent
  }, undefined)
}

export const findLocalAidFromSmids = async (
  client: SignifyClient,
  smids: []
) => {
  let aids = await client.identifiers().list(0, 24)
  const local = aids['aids'].filter((elem) => {
    return smids.some((x) => {
      return x === elem.prefix
    })
  })
  let localAID = await client.identifiers().get(local?.[0]?.name)

  return localAID
}

export const findAidByAlias = (
  aids: aid[] | null | undefined,
  alias: string | null | undefined
): aid | undefined => {
  if (!aids || !alias) {
    return undefined
  }

  return aids.find((aid) => aid.name === alias)
}

export const getExnMessageBySaid = async (
  client: SignifyClient,
  said: string
): Promise<any> => {
  let exnRes = await client.exchanges().get(said)
  return exnRes
}

export const parseIdentifierDto = async (
  client: SignifyClient,
  identifierDto: any,
  includeRawData: boolean = false
): Promise<aid> => {
  let isGroup = isGroupAid(identifierDto)
  let group: GroupAid = null
  if (isGroup === true) {
    let members = await getGroupMembersSigningThreshold(
      client,
      identifierDto.name
    )
    let localAid = getLocalMemberFromGroupAid(identifierDto)
    let witness = getFirstWitnessFromEnds(
      members?.find((x) => x.aid === localAid?.prefix)?.ends
    )
    group = {
      name: identifierDto.name,
      prefix: identifierDto.prefix,
      localAid: localAid,
      members: members,
      minSignersThreshold:
        members?.length > 0 ? members[0].minSignersThreshold : 0,
      witnessOobi: witness?.http
        ? formatUrlPath(witness.http, `oobi/${identifierDto.prefix}`)
        : null
    }
  }

  let identifier = {
    name: identifierDto.name,
    prefix: identifierDto.prefix,
    isMultisig: isGroup,
    group: group
  } as aid

  if (includeRawData === true) identifier.raw = identifierDto

  return identifier
}

export const parseIdentifierDtoList = async (
  client: SignifyClient,
  aids: any[]
): Promise<aid[]> => {
  let identifiers: aid[] = []
  if (aids && aids?.length > 0) {
    for (let i = 0; i < aids.length; i++) {
      const identifier = await parseIdentifierDto(client, aids[i])
      identifiers.push(identifier)
    }
  }
  return identifiers
}

export const addEndRole = async (
  client: SignifyClient,
  alias: string,
  prefix: string,
  role: string
) => {
  try {
    let res = await client.identifiers().addEndRole(alias, role, prefix)
    await waitOperationWithRetries(client, await res.op(), 10)
    //await delay(2000)
    const oobiRes = await client.oobis().get(alias, role)
    if (oobiRes && oobiRes?.oobis.length < 1) {
      await client.identifiers().addEndRole(alias, role, prefix)
    }
  } catch (ex) {
    console.error('Error in addEndRole', ex)
  }
}

/**
 * Get list of end role authorizations for a Keri idenfitier
 */
export const getEndRoles = async (
  client: SignifyClient,
  alias: string,
  role?: string
): Promise<any> => {
  const path =
    role !== undefined
      ? `/identifiers/${alias}/endroles/${role}`
      : `/identifiers/${alias}/endroles`
  const response: Response = await client.fetch(path, 'GET', null)
  if (!response.ok) throw new Error(await response.text())
  const result = await response.json()
  return result
}

/**
 * Test if end role is authorized for a Keri identifier
 */
export const hasEndRole = async (
  client: SignifyClient,
  alias: string,
  role: string,
  eid: string
): Promise<boolean> => {
  const list = await getEndRoles(client, alias, role)
  for (const i of list) {
    if (i.role === role && i.eid === eid) {
      return true
    }
  }
  return false
}

export const getRegistries = async (client: SignifyClient, name: string) => {
  return await client.registries().list(name)
}

/**
 * Rename a registry
 * @async
 * @param {string} alias Name or alias of the identifier
 * @param {string} registryName Current registry name
 * @param {string} newName New registry name
 * @returns {Promise<any>} A promise to the registry record
 */
export const renameRegistry = async (
  client: SignifyClient,
  alias: string,
  registryName: string,
  newName: string
) => {
  return await client.registries().rename(alias, registryName, newName)
}

/**
 * Rotate a single-sig identifier
 * @async
 * @param {string} name Name or alias of the identifier
 * @param {RotateIdentifierArgs} args Optional arguments related rotating an identfier
 * @returns {Promise<any>} A promise to the long running operation response
 */
export const rotateSingleSigIdentifier = async (
  client: SignifyClient,
  alias: string,
  args: RotateIdentifierArgs = {}
) => {
  let rotResult = await client.identifiers().rotate(alias, args)
  let op = await waitOperationWithRetries(client, await rotResult.op(), 10)
  return op
}

/**
 * Approve an event of delegatee using an interaction event
 * @async
 * @param {string} name Name or alias of the identifier
 * @param {object} [anchor] Event data to be anchored in the interaction event.
 * @returns {Promise<EventResult>} A promise to the interaction event result
 */
export const approveDelegation = async (
  client: SignifyClient,
  name: string,
  anchor: { i: string; s: string; d: string }
) => {
  return await client.identifiers().interact(name, anchor)
}

export const checkIfEventIsInKEL = async (
  client: SignifyClient,
  alias: string,
  event: KELEvent,
  checkIsWitnessed: boolean
): Promise<boolean | null> => {
  consoleLog(`Checking if KEL of identifier ${alias} has event: `, event)
  if (!event) return null

  let aid = await client.identifiers().get(alias)
  if (!aid) return null

  consoleLog(
    `Identifier ${alias} current state is at seq num ${aid.state?.s} and event said is: `,
    aid.state?.d
  )
  consoleLog('Identifier state seq num: ', event.s)
  consoleLog('Event seq num: ', event.s)
  consoleLog(
    `Identifer ${alias} witness count: ${aid.state?.b?.length},witness threshold: ${aid.state?.bt}`
  )
  consoleLog('Windexes: ', aid.windexes)

  let snerCurrent = new CesrNumber({}, undefined, aid.state?.s)
  let snerEvent = new CesrNumber({}, undefined, event.s)
  if (snerEvent.num === snerCurrent.num && event.d === aid.state?.d) {
    if (checkIsWitnessed === false) return true

    if (aid.windexes?.length >= aid.state?.bt) return true
    else return false
  } else if (snerEvent.num < snerCurrent.num) {
    // For now assume that event is in KEL.
    //TODO: For now we can get all events and then filter by seq num and then check if it is witnessed.
    return true
  } else return false
}

export async function waitForEventToBeInKEL(
  client: SignifyClient,
  alias: string,
  event: KELEvent,
  checkIsWitnessed: boolean,
  maxRetries: number = 60
): Promise<boolean | null> {
  const retryOptions: RetryOptions = {
    maxRetries: maxRetries,
    timeout: 600000 // 10 minutes
  }
  const condition = (data: boolean | null): boolean => {
    if (data === true) return true
    return false
  }

  let isAdded = await retryIf(
    () => checkIfEventIsInKEL(client, alias, event, checkIsWitnessed),
    condition,
    retryOptions
  )
  return isAdded
}
