import {
  LECredPayload,
  createGroupIdentifier,
  getKeyState,
  sendGroupIcpExnMessage,
  waitOperationWithRetries
} from '@/services/wallet'
import constants from '@/config/constants'
import { Notification } from '@/services/wallet'
import { markNotification } from '@/services/signify'
import { sendCredIssueExnMessage } from '@/services/wallet'
import { IssueCredentialResult, Saider, SignifyClient } from 'signify-ts'
import { Identifiers } from '@/state/meet'
import { compareParticipantAids } from '@/utils/ceremony'
import { calculateEqualMultisigWeightage } from '@/utils/aid'
import { rules } from './rules'
import { consoleError, consoleLog } from '@/utils/console-logger'

/**
 * @note 1. Initiate Multisig AID process
 * @note 2. Send exn message to other participants
 * @async
 * @returns {Promise<any>} A promise to the icp long running operation
 */
export const initiateLEMultisigInception = async (
  client: SignifyClient,
  otherMembers: Identifiers[],
  localAid: any,
  leGroupAidAlias: string,
  threshold: string
) => {
  consoleLog(`Starting  inception of LE multisig AID: `, leGroupAidAlias)
  if (!constants.WITNESS_AIDS) {
    throw new Error('Witnesses are not configured for the identifiers')
  }
  try {
    otherMembers.sort(compareParticipantAids)

    let states = []
    states.push(localAid.state)

    for (let i = 0; i < otherMembers.length; i++) {
      consoleLog(
        `Get key state of member AID ${otherMembers[i].name}, ${otherMembers[i].prefix} `
      )
      let state = await getKeyState(client, otherMembers[i].prefix)
      consoleLog('Key State: ', state)
      states.push(state[0])
    }
    let sortedStates = states.sort((a, b) =>
      a.i < b.i ? -1 : b.i > a.i ? 1 : 0
    )
    consoleLog('Sorted member states of LE multisig AID: ', sortedStates)

    let isith: string | number | string[] = threshold
    if (states.length === 1) {
      isith = ['1']
    }
    let nsith = isith
    consoleLog('LE multisig AID signing threshold: ', isith)

    if (!constants.WITNESS_AIDS) {
      throw new Error('Witnesses are not configured for the identifiers')
    }

    let witnessAids = constants.WITNESS_AIDS.split(',').map((item) =>
      item.trim()
    )

    consoleLog('SUBMITTING_LAR_MULTISIG_AID_INCEPTION_REQUEST...')

    const icpResult = await createGroupIdentifier(
      client,
      leGroupAidAlias,
      localAid,
      isith,
      nsith,
      sortedStates,
      sortedStates,
      witnessAids
    )
    consoleLog(
      `${localAid.name} initiated multisig inception, waiting for others to join...`
    )

    await sendGroupIcpExnMessage(client, localAid, sortedStates, icpResult)

    //let op = await icpResult.op()
    // consoleLog(
    //   `starting waiting for ICP operation to complete...`, op
    // )
    // let opComp = waitOperationWithTimeout(client, op, 60000)
    //consoleLog(`ICP operation completed : `, opComp)

    return icpResult
  } catch (ex) {
    consoleError('Error in inception of LE multisig AID: ' + ex.message, ex)
    throw ex
  }
}

export async function initiateLEIssuance(
  client: SignifyClient,
  alias: string,
  credData: LECredPayload,
  reg: any,
  recipient: any
) {
  let { attributes, edge, rules } = formatLECredData(credData)

  const time = new Date().toISOString().replace('Z', '000+00:00')
  const issueResult: IssueCredentialResult = await client.credentials().issue({
    issuerName: alias,
    registryId: reg,
    schemaId: constants.CREDENTIAL_TYPES.LEI,
    recipient: recipient,
    data: attributes,
    rules: rules && Saider.saidify({ d: '', rules })[1],
    source: edge && Saider.saidify({ d: '', edge })[1],
    datetime: time
  })

  await sendCredIssueExnMessage(client, alias, issueResult)

  await waitOperationWithRetries(client, issueResult.op, 30)
}

export async function autoJoinLEIssuance(
  client: SignifyClient,
  notification: Notification,
  exnMsg: any
) {
  let alias = exnMsg?.groupName
  let registries = await client.registries().list(alias)
  let reg = registries?.[0]?.regk

  const vcdata = {
    LEI: exnMsg.exn.e.acdc.a.LEI
  }

  const issueResult = await client.credentials().issue({
    issuerName: alias,
    registryId: reg,
    schemaId: constants.CREDENTIAL_TYPES.LEI,
    data: vcdata,
    datetime: exnMsg.exn.e.acdc.a.dt,
    recipient: exnMsg.exn.e.acdc.a.i,
    rules: exnMsg.exn.e.acdc.r,
    source: exnMsg.exn.e.acdc.e
  })

  await sendCredIssueExnMessage(client, alias, issueResult)
  await markNotification(client, notification.i)

  await waitOperationWithRetries(client, issueResult.op, 30)
}

export function formatLECredData(payload: LECredPayload): {
  attributes: any
  edge: any
  rules: any
} {
  const attributes = {
    LEI: payload.lei
  }
  const edge = {
    qvi: {
      n: payload.qviCredSaid,
      s: constants.CREDENTIAL_TYPES.QVI
    }
  }

  return { attributes, edge, rules }
}
