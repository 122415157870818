/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LarRemovalRequestDto
 */
export interface LarRemovalRequestDto {
    /**
     * 
     * @type {string}
     * @memberof LarRemovalRequestDto
     */
    workflowInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof LarRemovalRequestDto
     */
    workflowInstanceAssignedTaskId: string;
    /**
     * 
     * @type {string}
     * @memberof LarRemovalRequestDto
     */
    userId: string;
}

/**
 * Check if a given object implements the LarRemovalRequestDto interface.
 */
export function instanceOfLarRemovalRequestDto(value: object): boolean {
    if (!('workflowInstanceId' in value)) return false;
    if (!('workflowInstanceAssignedTaskId' in value)) return false;
    if (!('userId' in value)) return false;
    return true;
}

export function LarRemovalRequestDtoFromJSON(json: any): LarRemovalRequestDto {
    return LarRemovalRequestDtoFromJSONTyped(json, false);
}

export function LarRemovalRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LarRemovalRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'],
        'workflowInstanceAssignedTaskId': json['workflowInstanceAssignedTaskId'],
        'userId': json['userId'],
    };
}

export function LarRemovalRequestDtoToJSON(value?: LarRemovalRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'workflowInstanceAssignedTaskId': value['workflowInstanceAssignedTaskId'],
        'userId': value['userId'],
    };
}

