import { create } from 'zustand'
import { produce } from 'immer'
import { OORRecipient, WorkflowDisplayMode } from './types'
import { OorWorkflow } from '@/models/oor-vlei-workflow'
import TaskStatus from '@/enums/taskStatus'
import SchedulingStatus from '@/enums/schedulingStatus'
import { commonWorkflowService } from '@/utils/apis'

export interface ITimeSlots {
  start: string
  end: string
}

type OorWorkflowStore = {
  workflow: OorWorkflow
  recipients: OORRecipient[]
  mode: WorkflowDisplayMode
  scheduling: {
    status: TaskStatus
    qarTimeSlot: ITimeSlots[]
    ceremonyTime?: string
    inviteeLARs: Set<string>
  }
  setMode: (mode: WorkflowDisplayMode) => void
  setWorkflow: (data: OorWorkflow) => void
  setRecipients: (recipients: OORRecipient[]) => void
  addRecipient: () => void
  deleteRecipient: (index: number) => void
  updateRecipient: (index: number, data: OORRecipient) => void
  setQarTimeSlot?: (qarTimeSlot: { start: string; end: string }[]) => void
  setCeremonyTime?: (ceremonyTime: string) => void
  resetRecipients: () => void
  addInviteeLARs: (larId: string) => void
  removeInviteeLARs: (larId: string) => void
}

export const useOorWorkflowStore = create<OorWorkflowStore>((set, get) => ({
  workflow: null,
  scheduling: {
    status: TaskStatus.ASSIGNED,
    qarTimeSlot: [],
    inviteeLARs: new Set<string>()
  },
  recipients: [
    {
      userId: '',
      firstName: '',
      lastName: '',
      email: '',
      orgName: '',
      oorTitle: '',
      shortTitle: '',
      elfCode: ''
    }
  ], // Start with one empty recipient
  mode: WorkflowDisplayMode.NON_EDITABLE,
  setWorkflow: (data: OorWorkflow) =>
    set(
      produce((state: OorWorkflowStore) => {
        state.workflow = data
      })
    ),
  setRecipients: (recipients: OORRecipient[]) =>
    set(
      produce((state: OorWorkflowStore) => {
        state.recipients = recipients
      })
    ),
  setMode: (mode: WorkflowDisplayMode) =>
    set(
      produce((state: OorWorkflowStore) => {
        state.mode = mode
      })
    ),
  addRecipient: () =>
    set(
      produce((state: OorWorkflowStore) => {
        state.recipients.push({
          firstName: '',
          lastName: '',
          email: '',
          orgName: '',
          oorTitle: '',
          shortTitle: '',
          elfCode: ''
        })
      })
    ),
  deleteRecipient: (index: number) =>
    set(
      produce((state: OorWorkflowStore) => {
        state.recipients.splice(index, 1)
      })
    ),
  updateRecipient: (index: number, data: OORRecipient) =>
    set(
      produce((state: OorWorkflowStore) => {
        state.recipients[index] = { ...state.recipients[index], ...data }
      })
    ),
  setQarTimeSlot: (qarTimeSlot: ITimeSlots[]) =>
    set((state) => ({
      ...state,
      scheduling: {
        ...state.scheduling,
        qarTimeSlot: qarTimeSlot
      }
    })),
  setCeremonyTime: (ceremonyTime: string) =>
    set((state) => ({
      ...state,
      scheduling: {
        ...state.scheduling,
        ceremonyTime: ceremonyTime
      }
    })),
  resetRecipients: () =>
    set(
      produce((state: OorWorkflowStore) => {
        state.recipients = [
          {
            userId: '',
            firstName: '',
            lastName: '',
            email: '',
            orgName: '',
            oorTitle: '',
            shortTitle: '',
            elfCode: '',
            error: null
          }
        ]
      })
    ),
  addInviteeLARs: (larId: string) => {
    set((state) => ({
      ...state,
      scheduling: {
        ...state.scheduling,
        inviteeLARs: new Set([...state.scheduling.inviteeLARs, larId])
      }
    }))
  },
  removeInviteeLARs: (larId: string) => {
    set((state) => ({
      ...state,
      scheduling: {
        ...state.scheduling,
        inviteeLARs: new Set(
          [...state.scheduling.inviteeLARs].filter((id) => id !== larId)
        )
      }
    }))
  }
}))
