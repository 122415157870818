import constants from '@/config/constants'
import {
  ChallengeString,
  CreateLEMultisigAidMessagePayload,
  ICredentialIssuanceStep,
  ILARVote,
  ILeadQAR,
  IMultisigMemberSignal,
  IOORAuth,
  RoleCredentialRecipient,
  Identifiers,
  MULTISIG_AID_MESSAGE_TYPE,
  Participant,
  QAR_CEREMONY_STEP,
  TriggerSignify,
  ISecondaryQARApproval
} from '@/state/meet'
import { IContact, aid } from '@/state/signify'
import { capitalCase } from 'change-case'
import { differenceBy, head, isEqual } from 'lodash'
import { GeneralJitsiMessage } from '@/state/models'
import { PossibleOorRecipientDto } from '@/api/origin-workflow-svc'

export const prepareCurrentLARAuthCount = (count: number) => {
  return JSON.stringify({
    currentLARAuthCount: count
  })
}

export const extractCurrentLARAuthCount = (message: string) => {
  try {
    const msg = JSON.parse(message)
    return msg.currentLARAuthCount as number
  } catch (ex) {
    return
  }
}

export const prepareQARCeremonySteps = (step: QAR_CEREMONY_STEP) => {
  return JSON.stringify({
    QARCeremonySteps: step
  })
}

export const extractQARCeremonyStep = (message: string) => {
  try {
    const msg = JSON.parse(message)
    return msg.QARCeremonySteps as QAR_CEREMONY_STEP
  } catch (ex) {
    return
  }
}

export interface CustomJitsiMessage {
  message: string
  payload: any
}

export function extractJitsiMessage(message: string): GeneralJitsiMessage {
  try {
    const parsed: GeneralJitsiMessage = JSON.parse(message)
    if (parsed.generalJitsiPayload && parsed.generalJitsiPayload) {
      return parsed
    }
  } catch (error) {
    console.error('Failed to parse message:', error)
    return undefined
  }
  return undefined
}

export const PrepareQARChallenge = (
  challengeString: string,
  from: string,
  to: string,
  triggerSignify: TriggerSignify,
  completed: boolean
) => {
  return JSON.stringify({
    QARChallengeString: {
      challengeString,
      from,
      to,
      triggerSignify,
      completed
    } as ChallengeString
  })
}

export const extractQARChallengeMessages = (message: string) => {
  try {
    const msg = JSON.parse(message)
    return msg.QARChallengeString as ChallengeString
  } catch (ex) {
    return
  }
}

export const PrepareLARChallenge = (
  challengeString: string,
  from: string,
  to: string,
  triggerSignify: TriggerSignify,
  completed: boolean
) => {
  return JSON.stringify({
    LARChallengeString: {
      challengeString,
      from,
      to,
      triggerSignify,
      completed
    } as ChallengeString
  })
}

export const extractLARChallengeMessages = (message: string) => {
  try {
    const msg = JSON.parse(message)
    return msg.LARChallengeString as ChallengeString
  } catch (ex) {
    return
  }
}

export const PrepareIHeard = (
  challengeString: string,
  from: string,
  to: string,
  triggerSignify: TriggerSignify,
  completed: boolean
) => {
  return JSON.stringify({
    IHeard: {
      challengeString,
      from,
      to,
      triggerSignify,
      completed
    } as ChallengeString
  })
}

export const extractIHeard = (message: string) => {
  try {
    const msg = JSON.parse(message)
    return msg.IHeard as ChallengeString
  } catch (ex) {
    return
  }
}

export const prepareActiveLAR = (activeLAR: string) => {
  return JSON.stringify({ activeLAR })
}

export const extractActiveLAR = (message: string) => {
  try {
    const msg = JSON.parse(message)
    return msg.activeLAR as string
  } catch (ex) {
    return ''
  }
}

export const prepareActiveHearingLAR = (activeHearingLAR: string) => {
  return JSON.stringify({ activeHearingLAR })
}

export const extractActiveHearingLAR = (message: string) => {
  try {
    const msg = JSON.parse(message)
    return msg.activeHearingLAR as string
  } catch (ex) {
    return ''
  }
}

export const prepareActiveOOR = (activeOOR: string) => {
  return JSON.stringify({ activeOOR })
}

export const extractActiveOOR = (message: string) => {
  try {
    const msg = JSON.parse(message)
    return msg.activeOOR as string
  } catch (ex) {
    return ''
  }
}

export const prepareMultiSigSigner = (selectedSigner: number) => {
  return JSON.stringify({ selectedSigner })
}

export const extractMultiSigSigner = (message: string) => {
  try {
    const msg = JSON.parse(message)
    return msg.selectedSigner as number
  } catch (ex) {
    return 0
  }
}

export const prepareLARVotes = (LARVotes: ILARVote) => {
  return JSON.stringify({ LARVotes })
}

export const extractLARVotes = (message: string) => {
  try {
    const msg = JSON.parse(message)
    return msg.LARVotes as ILARVote
  } catch (ex) {
    return
  }
}

export const prepareOORAuth = (OORAuth: IOORAuth) => {
  return JSON.stringify({ OORAuth })
}

export const extractOORAuth = (message: string) => {
  try {
    const msg = JSON.parse(message)
    return msg.OORAuth as IOORAuth
  } catch (ex) {
    return
  }
}

// export const prepareRoleAuthCandidate = (
//   OORAuth: IOORAuth,
//   roleAuthCandidate: IRoleAuthCandidate
// ): string => {
//   let updatedRoleAuthCandidate: IRoleAuthCandidate

//   if (roleAuthCandidate) {
//     updatedRoleAuthCandidate = {
//       ...roleAuthCandidate,
//       ...OORAuth
//     }
//   } else {
//     updatedRoleAuthCandidate = {
//       ...OORAuth,
//       issuerMembers: []
//     }
//   }
//   return JSON.stringify({ roleAuthCandidate: updatedRoleAuthCandidate })
// }

// export const extractRoleAuthCandidate = (message: string) => {
//   try {
//     const msg = JSON.parse(message)
//     return msg.roleAuthCandidate as IRoleAuthCandidate
//   } catch (ex) {
//     return
//   }
// }

export const prepareRoleCredRecipients = (
  payload: RoleCredentialRecipient[]
) => {
  return JSON.stringify({ roleCredRecipients: payload })
}

export const extractRoleCredRecipients = (message: string) => {
  try {
    const msg = JSON.parse(message)
    return msg.roleCredRecipients as RoleCredentialRecipient[]
  } catch (ex) {
    return
  }
}

export const prepareLEMultisigAidInitiator = (
  payload: CreateLEMultisigAidMessagePayload
) => {
  return JSON.stringify({ payload })
}

export const extractLEMultisigAidInitiator = (message: string) => {
  try {
    const msg = JSON.parse(message)
    if (
      msg?.payload?.msgType ===
      MULTISIG_AID_MESSAGE_TYPE.START_MULTISIG_AID_INCEPTION
    )
      return msg.payload as CreateLEMultisigAidMessagePayload
    else return null
  } catch (ex) {
    return null
  }
}

export const prepareMultisigMemberSignal = (payload: IMultisigMemberSignal) => {
  return JSON.stringify({ payload })
}

export const extractMultisigMemberSignal = (message: string) => {
  try {
    const msg = JSON.parse(message)
    return msg.payload as IMultisigMemberSignal
  } catch (ex) {
    return null
  }
}

export const prepareJoinMultisigMember = (
  payload: CreateLEMultisigAidMessagePayload
) => {
  return JSON.stringify({ payload })
}

export const extractJoinMultisigMember = (message: string) => {
  try {
    const msg = JSON.parse(message)
    if (
      msg?.payload?.msgType ===
      MULTISIG_AID_MESSAGE_TYPE.JOIN_MULTISIG_AID_INCEPTION
    )
      return msg.payload as CreateLEMultisigAidMessagePayload
    else return null
  } catch (ex) {
    return
  }
}

export const prepareCredentialIssuanceStep = (
  payload: ICredentialIssuanceStep
) => {
  return JSON.stringify({ payload })
}

export const extractCredentialIssuanceStep = (message: string) => {
  try {
    const msg = JSON.parse(message).payload as ICredentialIssuanceStep
    if (msg.messageId) return msg
    return
  } catch (ex) {
    return
  }
}

export const prepareLeadQAR = (leadQAR: string) => {
  return JSON.stringify({ leadQAR } as ILeadQAR)
}

export const extractLeadQAR = (message: string) => {
  try {
    const msg = JSON.parse(message) as ILeadQAR
    if (msg.leadQAR) return msg
    return
  } catch (ex) {
    return
  }
}

export const prepareSecondaryQARApproval = (secondaryQAR: string) => {
  return JSON.stringify({ secondaryQAR } as ISecondaryQARApproval)
}

export const extractSecondaryQARApproval = (message: string) => {
  try {
    const msg = JSON.parse(message) as ISecondaryQARApproval
    if (msg.secondaryQAR) return msg
    return
  } catch (ex) {
    return
  }
}

export const allVerified = (participants: Participant[]) => {
  return (
    participants.length &&
    participants.filter((participant) => !participant.verified).length == 0
  )
}

export const isParticipantContact = (
  participant: Participant,
  contacts: IContact[]
) => {
  const ContactsOOBIs = contacts.map((contact) => contact.oobi).sort()
  const OOBIs = participant.identifiers
    .map((identifier) => identifier.oobi)
    .flat()
    .sort()
  return isEqual(OOBIs, ContactsOOBIs)
}

export const isIdentifierAConnection = (
  identifier: Identifiers,
  contacts: IContact[]
) => {
  let isIdentifierConnection = true
  contacts.forEach((contact) => {
    if (identifier.oobi.includes(contact.oobi)) isIdentifierConnection = false
  })
  return isIdentifierConnection
}

export const getRoleFromAlias = (alias: string, recipientRole?: string) => {
  let role

  if (
    recipientRole &&
    alias.toUpperCase().includes(`AS-${recipientRole.toUpperCase()}`)
  )
    return recipientRole

  Object.values(constants.ROLES).forEach((_role) => {
    if (alias.toUpperCase().includes(`AS-${_role}`)) role = _role
  })
  return role
}

export const getTitleFromShortTitle = (
  shortTitle: string = '',
  recipients: PossibleOorRecipientDto[] = []
) => {
  let title = shortTitle
  recipients.forEach((recipient) => {
    const grant = recipient.titleAndGrantStatusList.find(
      (grant) => grant.shortTitle.toUpperCase() === shortTitle.toUpperCase()
    )
    grant && (title = grant.title)
  })
  return title
}

export const findAbsentParticipant = (
  actualParticipants: Participant[],
  storeParticipants: Participant[]
) => {
  if (storeParticipants.length < actualParticipants.length)
    return head(differenceBy(actualParticipants, storeParticipants, 'id'))
  else return head(differenceBy(storeParticipants, actualParticipants, 'id'))
}

export const getParticipantByID = (id: string, participants: Participant[]) => {
  return participants.find((p) => p.id === id) || ({} as Participant)
}

export const uniqChallengeString = (
  challengeString: ChallengeString,
  challengeStrings: ChallengeString[]
) => {
  const index = challengeStrings.findIndex(
    (cs) => cs.to === challengeString.to && cs.from === challengeString.from
  )
  const newChallengeStrings = [...challengeStrings]
  if (index > -1) newChallengeStrings[index] = challengeString
  else newChallengeStrings.push(challengeString)
  return newChallengeStrings
}

export const getAIDFromOOBI = (oobi: string) => {
  const regex = /(?<=\/oobi\/)[^/]+/
  return head(oobi.match(regex))
}

export const getLocalIdentifierByRole = (
  identifiers: aid[] = [],
  role: string
) => {
  return identifiers.find((identifier) =>
    identifier.name.toUpperCase().includes(`AS-${role.toUpperCase()}`)
  )
}

export const getIdentifierByRole = (
  identifiers: Identifiers[] = [],
  role: string
) => {
  return identifiers.find((identifier) =>
    identifier.name.toUpperCase().includes(`AS-${role.toUpperCase()}`)
  )
}

export const getRecipientRoleByID = (
  id: string,
  recipients: PossibleOorRecipientDto[]
) => {
  const recipient = recipients.find((recipient) => recipient.userId === id)
  const possibleRoles = recipient?.titleAndGrantStatusList.filter(
    (title) => !Object.keys(constants.ROLES).includes(title.title.toUpperCase())
  )

  return capitalCase(head(possibleRoles)?.title)
}

export const compareParticipantAids = (a: Identifiers, b: Identifiers) => {
  if (a.prefix < b.prefix) {
    return -1
  }
  if (a.prefix > b.prefix) {
    return 1
  }
  return 0
}

export const groupDevicesByKind = (devices: MediaDeviceInfo[]) => {
  return {
    audioInput: devices.filter((device) => device.kind === 'audioinput'),
    audioOutput: devices.filter((device) => device.kind === 'audiooutput'),
    videoInput: devices.filter((device) => device.kind === 'videoinput')
  }
}

export const getStepNameSymbolicId = (step: QAR_CEREMONY_STEP) => {
  switch (step) {
    case QAR_CEREMONY_STEP.PHOTO_VERIFY:
      return 'ceremony.header.step.photoVerify'
    case QAR_CEREMONY_STEP.START_CEREMONY:
      return 'ceremony.header.step.startCeremony'
    case QAR_CEREMONY_STEP.QAR_CHALLENGE:
      return 'ceremony.header.step.qarChallenge'
    case QAR_CEREMONY_STEP.QAR_CHALLENGE_OOR:
      return 'ceremony.header.step.qarChallenge'
    case QAR_CEREMONY_STEP.QAR_OOR_CEREMONY_STARTED:
      return 'ceremony.header.step.ceremonyStarted'
    case QAR_CEREMONY_STEP.QAR_CHALLENGE_STARTED:
      return 'ceremony.header.step.qarChallenge'
    case QAR_CEREMONY_STEP.LAR_CHALLENGE:
      return 'ceremony.header.step.larChallenge'
    case QAR_CEREMONY_STEP.LAR_CHALLENGE_STARTED:
      return 'ceremony.header.step.larChallenge'
    case QAR_CEREMONY_STEP.OOR_AUTH_PRE:
      return 'ceremony.header.step.authCredential'
    case QAR_CEREMONY_STEP.OOR_AUTH_STARTED:
      return 'ceremony.header.step.authCredential'
    case QAR_CEREMONY_STEP.OOR_AUTH_FINAL:
      return 'oor.label.finalIssuance'
    case QAR_CEREMONY_STEP.MULTI_SIG_STARTED:
      return 'ceremony.header.step.multiSig'
    case QAR_CEREMONY_STEP.COMPLETED:
      return 'ceremony.header.step.completed'
    default:
      return 'oor.ceremony.issuanceCeremony.header'
  }
}
