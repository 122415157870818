import constants from '@/config/constants'
import useStore from '@/state/store'
import { getJWTToken } from './auth'
import { CustomError, IErrorResp, prepareError } from './CustomException'

interface IOptions {
  BASE_URL?: string
  QUERY?: object
}

const defaultOptions: IOptions = {
  BASE_URL: '',
  QUERY: {}
}

export const get = async (url: string, options: IOptions = defaultOptions) => {
  var headers = new Headers()
  headers.append('Content-Type', 'application/json')

  const jwtToken = getJWTToken()
  jwtToken && headers.append('Authorization', jwtToken)

  //@ts-ignore
  const queryParam = new URLSearchParams(options.QUERY).toString()
  const FULL_URL = options.BASE_URL + url + (queryParam ? `?${queryParam}` : '')

  try {
    const response = await fetch(FULL_URL, {
      method: 'GET',
      headers: headers
    })

    console.log('GET_FETCH_RESPONSE : ' + JSON.stringify(response))

    if (response.status == 204) return
    if (response.ok) return await response.json()
    else if (response.status === 401) {
      console.log(
        `GET_HTTP_REQUEST_STATUS : ${response.status}, redirecting to signin page`
      )
      useStore.getState().killSession()
      useStore.getState().clearSignify()
    } else {
      const respObj = await response.json()
      throw new CustomError({
        code: respObj?.code || constants.ERROR_CODES.UNKNOWN,
        title: respObj?.title || '',
        type: respObj?.type || '',
        detail: respObj?.detail || '',
        instance: respObj?.instance || ''
      } as IErrorResp)
    }
  } catch (ex) {
    throw prepareError(ex)
  }
}

export const post = async (
  url: string,
  payload: any,
  options: IOptions = defaultOptions
) => {
  var headers = new Headers()
  headers.append('Content-Type', 'application/json')

  const jwtToken = getJWTToken()
  jwtToken && headers.append('Authorization', jwtToken)

  //  @ts-ignore
  const queryParam = new URLSearchParams(options.QUERY).toString()
  const FULL_URL = options.BASE_URL + url + (queryParam ? `?${queryParam}` : '')

  try {
    const response = await fetch(FULL_URL, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload)
    })
    if (response.status == 204) return
    if (response.ok) return await response.json()
    else if (response.status === 401) {
      useStore.getState().killSession()
      useStore.getState().clearSignify()
    } else {
      const respObj = await response.json()
      throw new CustomError({
        code: respObj?.code || constants.ERROR_CODES.UNKNOWN,
        title: respObj?.title || '',
        type: respObj?.type || '',
        detail: respObj?.detail || '',
        instance: respObj?.instance || ''
      } as IErrorResp)
    }
  } catch (ex) {
    throw prepareError(ex)
  }
}

export const postMultipartData = async (
  url: string,
  payload: FormData,
  options: IOptions = defaultOptions
) => {
  var headers = new Headers()

  const jwtToken = getJWTToken()
  jwtToken && headers.append('Authorization', jwtToken)

  //  @ts-ignore
  const queryParam = new URLSearchParams(options.QUERY).toString()
  const FULL_URL = options.BASE_URL + url + (queryParam ? `?${queryParam}` : '')

  try {
    const response = await fetch(FULL_URL, {
      method: 'POST',
      headers: headers,
      body: payload
    })

    if (response.ok) return await response.json()
    throw new Error('Something went wrong')
  } catch (ex) {
    throw new Error('Failed to post ' + options.BASE_URL + url)
  }
}

export const getAsBlob = async (
  url: string,
  options: IOptions = defaultOptions
) => {
  var headers = new Headers()
  headers.append('Content-Type', 'application/json')

  const jwtToken = getJWTToken()
  jwtToken && headers.append('Authorization', jwtToken)

  //@ts-ignore
  const queryParam = new URLSearchParams(options.QUERY).toString()
  const FULL_URL = options.BASE_URL + url + (queryParam ? `?${queryParam}` : '')

  try {
    const response = await fetch(FULL_URL, {
      method: 'GET',
      headers: headers
    })

    if (response.status == 204) return
    if (response.ok) return await response.blob()
    else if (response.status === 401) {
      console.log(
        `GET_HTTP_REQUEST_STATUS : ${response.status}, redirecting to signin page`
      )
      useStore.getState().killSession()
      useStore.getState().clearSignify()
    } else {
      const respObj = await response.json()
      throw new CustomError({
        code: respObj?.code || constants.ERROR_CODES.UNKNOWN,
        title: respObj?.title || '',
        type: respObj?.type || '',
        detail: respObj?.detail || '',
        instance: respObj?.instance || ''
      } as IErrorResp)
    }
  } catch (ex) {
    throw prepareError(ex)
  }
}

export default {
  get,
  post,
  postMultipartData,
  getAsBlob
}
