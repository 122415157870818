/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VLEIPrerequisitesRequestDto
 */
export interface VLEIPrerequisitesRequestDto {
    /**
     * 
     * @type {string}
     * @memberof VLEIPrerequisitesRequestDto
     * @deprecated
     */
    modelId?: string;
    /**
     * 
     * @type {string}
     * @memberof VLEIPrerequisitesRequestDto
     */
    orgId?: string;
    /**
     * 
     * @type {number}
     * @memberof VLEIPrerequisitesRequestDto
     */
    requiredLarsNumber?: number;
}

/**
 * Check if a given object implements the VLEIPrerequisitesRequestDto interface.
 */
export function instanceOfVLEIPrerequisitesRequestDto(value: object): boolean {
    return true;
}

export function VLEIPrerequisitesRequestDtoFromJSON(json: any): VLEIPrerequisitesRequestDto {
    return VLEIPrerequisitesRequestDtoFromJSONTyped(json, false);
}

export function VLEIPrerequisitesRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VLEIPrerequisitesRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'modelId': json['modelId'] == null ? undefined : json['modelId'],
        'orgId': json['orgId'] == null ? undefined : json['orgId'],
        'requiredLarsNumber': json['requiredLarsNumber'] == null ? undefined : json['requiredLarsNumber'],
    };
}

export function VLEIPrerequisitesRequestDtoToJSON(value?: VLEIPrerequisitesRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'modelId': value['modelId'],
        'orgId': value['orgId'],
        'requiredLarsNumber': value['requiredLarsNumber'],
    };
}

