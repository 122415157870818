import { hasKey } from '@/utils/common'
import constants from '@/config/constants'
import signify, {
  Algos,
  CesrNumber,
  CreateIdentiferArgs,
  Serder,
  Siger,
  SignifyClient,
  messagize,
  randomNonce
} from 'signify-ts'
import { findLocalAidFromSmids, getKeyState } from './aid'
import useStore from '@/state/store'
import { GroupMember, Notification } from './types'
import { generateAlias } from '@/utils/aid'
import { consoleError, consoleLog } from '@/utils/console-logger'
//import { GroupMember } from '@/state/signify'

/**
 * Get the members of a group identifier
 * @async
 * @param {string} alias - Name or alias of the identifier
 * @returns {Promise<any>} - A promise to the list of members
 */
export const getGroupMembers = async (client: SignifyClient, alias: string) => {
  return await client.identifiers().members(alias)
}

/**
 * Get multisig exn request data
 * @async
 * @param {string} said - SAID of exn message to load
 * @returns {Promise<any>} A promise to the list of replay messages
 */
export const getGroupExnRequest = async (
  client: SignifyClient,
  said: string
): Promise<any> => {
  return await client.groups().getRequest(said)
}

/**
 * @note 1. Initiate Multisig AID process
 * @note 2. Send exn message
 * @note 3. Signal other participant to poll for notification
 * @async
 * @returns {Promise<any>} A promise to the list of random words
 */
export const initiateGroupInception = async (
  client: SignifyClient,
  alias: string,
  localAid: any,
  isith: string | number | string[],
  nsith: string | number | string[],
  states: any,
  rstates: any,
  delegator: string
) => {
  if (!constants.WITNESS_AIDS) {
    throw new Error('Witnesses are not configured for the identifiers')
  }
  try {
    let witnessAids = constants.WITNESS_AIDS.split(',').map((item) =>
      item.trim()
    )
    let args: CreateIdentiferArgs = {
      algo: signify.Algos.group,
      mhab: localAid,
      isith: isith,
      nsith: nsith,
      states: states,
      rstates: rstates,
      wits: witnessAids,
      toad: witnessAids.length
    }
    if (delegator) args.delpre = delegator

    consoleLog('Create multisig identifer args: ', JSON.stringify(args))
    let icpResult = await client.identifiers().create(alias, args)

    //let op = await icpResult?.op()
    let sigers = icpResult.sigs.map((sig: any) => new Siger({ qb64: sig }))

    let ims = signify.d(messagize(icpResult.serder, sigers))
    let atc = ims.substring(icpResult.serder.size)
    let embeds = {
      icp: [icpResult.serder, atc]
    }

    let smids = states.map((state) => state['i'])
    let recp = []
    for (let indx = 0; indx < states.length; indx++) {
      if (states[indx].i === localAid.prefix) {
        continue
      }
      recp.push(states[indx].i)
    }

    if (recp.length > 0) {
      await client
        .exchanges()
        .send(
          localAid.name,
          'multisig',
          localAid,
          '/multisig/icp',
          { gid: icpResult.serder.pre, smids: smids, rmids: smids },
          embeds,
          recp
        )
    }
    consoleLog(
      `${localAid.name} initiated multisig inception, waiting for others to join...`
    )

    // while (!op['done']) {
    //   op = await operationsInstance.get(op.name)
    //   await new Promise((resolve) => setTimeout(resolve, 1000)) // sleep for 1 second
    // }
    return icpResult
  } catch (ex) {
    consoleError('Error in initiate group aid inception: ' + ex.message, ex)
    throw ex
  }
}

/**
 * Create a group identifier
 * @async
 * @param {SignifyClient} client signify client instance
 * @param {string} groupAlias - Alias of group identifier
 * @param {any} localAid - Local member identifier
 * @param {any[]} isith - current key threshold
 * @param {any[]} nsith - next keys threshold
 * @param {any} states Array of member states
 * @param {any} rstates Array of rot member states
 * @returns {signify.EventResult} The inception result
 */
export const createGroupIdentifier = async (
  client: SignifyClient,
  groupAlias: string,
  localAid: any,
  isith: string | any[],
  nsith: string | any[],
  states: any,
  rstates: any,
  wits: any[]
): Promise<signify.EventResult> => {
  let args: CreateIdentiferArgs = {
    algo: signify.Algos.group,
    mhab: localAid,
    isith: isith,
    nsith: nsith,
    states: states,
    rstates: rstates,
    wits: wits,
    toad: wits.length
  }
  let icpResult = await client.identifiers().create(groupAlias, args)
  return icpResult
}

/**
 * Create a group identifier
 * @async
 * @param {SignifyClient} client signify client instance
 * @param {any} localAid - Local member identifier
 * @param {any} states Array of member states
 * @param {signify.EventResult} icpResult Result to multisig identifier inception
 * @returns {signify.EventResult} The inception result
 */
export async function sendGroupIcpExnMessage(
  client: SignifyClient,
  localAid: any,
  states: any[],
  icpResult: signify.EventResult
) {
  let serder = icpResult.serder

  let sigs = icpResult.sigs
  let sigers = sigs.map((sig: any) => new Siger({ qb64: sig }))
  let ims = signify.d(messagize(serder, sigers))
  let atc = ims.substring(serder.size)
  let embeds = {
    icp: [serder, atc]
  }

  let smids = states.map((state) => state['i'])
  let recp = []
  for (let indx = 0; indx < states.length; indx++) {
    if (states[indx].i === localAid.prefix) {
      continue
    }
    recp.push(states[indx]['i'])
  }

  if (recp.length > 0) {
    await client
      .exchanges()
      .send(
        localAid.name,
        'multisig',
        localAid,
        '/multisig/icp',
        { gid: serder.pre, smids: smids, rmids: smids },
        embeds,
        recp
      )
  }
  console.log(`${localAid.name} sent multisig/icp exchange message...`)
}

export const joinGroupInception = async (
  client: SignifyClient,
  icpExnMessageSaid: string,
  newAlias: string
) => {
  consoleLog(
    `starting joinGroupInception for alias ${newAlias} and EXN said : `,
    icpExnMessageSaid
  )
  let res = await client.groups().getRequest(icpExnMessageSaid)
  consoleLog(`group request : `, res)
  let exn = res[0].exn
  let icp = exn.e.icp

  let localAid = await findLocalAidFromSmids(client, exn.a.smids)

  let states = []
  let sortedParticipants = exn.a.smids.sort((a, b) =>
    a < b ? -1 : b > a ? 1 : 0
  )
  for (let i = 0; i < sortedParticipants.length; i++) {
    let state = await getKeyState(client, sortedParticipants[i])
    states.push(state[0])
  }

  consoleLog(`sorted states : `, states)
  let icpResult = await client.identifiers().create(newAlias, {
    algo: Algos.group,
    mhab: localAid,
    isith: icp.kt,
    nsith: icp.nt,
    toad: parseInt(icp.bt),
    wits: icp.b,
    states: states,
    rstates: states,
    delpre: icp.di
  })

  consoleLog(` ICP event creted : `, icpResult)

  consoleLog(`Sending ICP event to otehr members...`)
  await sendGroupIcpExnMessage(client, localAid, states, icpResult)
  consoleLog(`Sent ICP event to other members`)

  // let op = await icpResult.op()
  // console.log(`${localAid.name} joined multisig, waiting for others...`)

  // const operationsInstance = client.operations()
  // while (!op['done']) {
  //   op = await operationsInstance.get(op.name)
  //   await new Promise((resolve) => setTimeout(resolve, 1000))
  // }
  return icpResult
}

export async function handleJoinIcpExnMessage(
  notification: Notification
): Promise<void> {
  let client = useStore.getState().signifyClient
  let res = await getGroupExnRequest(client, notification.a.d)
  let exn = res?.[0]?.exn
  let icp = exn.e.icp

  let userInfo = useStore.getState().authentication?.userInfo
  let newAlias = generateAlias(
    `${userInfo?.firstName} ${userInfo?.lastName}`,
    'legal-entity',
    userInfo?.orgs?.[0]?.name,
    false,
    true
  )

  let localAid = await findLocalAidFromSmids(client, exn.a.smids)

  let states = []
  let sortedParticipants = exn.a.smids.sort((a, b) =>
    a < b ? -1 : b > a ? 1 : 0
  )
  for (let i = 0; i < sortedParticipants.length; i++) {
    let state = await getKeyState(client, sortedParticipants[i])
    states.push(state[0])
  }

  let icpResult = await client.identifiers().create(newAlias, {
    algo: Algos.group,
    mhab: localAid,
    isith: icp.kt,
    nsith: icp.nt,
    toad: parseInt(icp.bt),
    wits: icp.b,
    states: states,
    rstates: states,
    delpre: icp.di
  })

  let op = await icpResult.op()
  console.log(`${localAid.name} joined multisig, waiting for others...`)

  const operationsInstance = client.operations()
  while (!op['done']) {
    op = await operationsInstance.get(op.name)
    await new Promise((resolve) => setTimeout(resolve, 1000))
  }
  return op['response']
}

// export async function initiateEndRoleAuth(
//   client: SignifyClient,
//   alias: string
// ): Promise<void> {
//   const members = await client.identifiers().members('multisig');
//   console.log('>>>>> Member1_get_multisig_aid_members : ' + JSON.stringify(members));
//   let hab = await client.identifiers().get('multisig');
//   let aid = hab['prefix'];
//   const signing = members['signing'];
//   const eid1 = Object.keys(signing[0].ends.agent)[0];
//   // other agent eids can be obtained with
//   // let eid2 = Object.keys(signing[1].ends.agent)[0];
//   // let eid3 = Object.keys(signing[2].ends.agent)[0];
//   console.log(`Starting multisig end role authorization for agent ${eid1}`);

//   // initial stamp for the event that will be passed in the exn message
//   // to the other members
//   let stamp = new Date().toISOString().replace('Z', '000+00:00');

//   let endRoleRes = await client
//     .identifiers()
//     .addEndRole('multisig', 'agent', eid1, stamp);
//   console.log('>>>>> Member1_addEndRole_fn_endRoleRes : ' + JSON.stringify(endRoleRes));
//  let op = await endRoleRes.op();
//   let rpy = endRoleRes.serder;
//   let sigs = endRoleRes.sigs;
//   let mstate = hab['state'];
//   let sner = new CesrNumber({}, undefined, ghabState['ee']['s'])
//   let seal = [
//     'SealEvent',
//     { i: hab['prefix'], s: sner.num, d: mstate['ee']['d'] },
//   ];
//   let sigers = sigs.map((sig) => new signify.Siger({ qb64: sig }));
//   let roleims = signify.d(
//     signify.messagize(rpy, sigers, seal, undefined, undefined, false)
//   );
//   let atc = roleims.substring(rpy.size);
//   let roleembeds = {
//     rpy: [rpy, atc],
//   };
//   let recp = [aid2['state'], aid3['state']].map((state) => state['i']);
//   let res = await client1
//     .exchanges()
//     .send(
//       'member1',
//       'multisig',
//       aid1,
//       '/multisig/rpy',
//       { gid: aid },
//       roleembeds,
//       recp
//     );
//   console.log(
//     `Member1 authorized agent role to ${eid1}, waiting for others to authorize...`
//   );
//   console.log('>>>>> Member1_send_end_role_exn_endRoleRes : ' + JSON.stringify(res));
// }

export async function multisigCreateRegistry(
  client: SignifyClient,
  groupName: string,
  memberName: string
) {
  consoleLog(`Starting create registry for identifier ${groupName}`)
  const gHab = await client.identifiers().get(groupName)

  consoleLog(`Group identifier : `, gHab)
  memberName = memberName ? memberName : gHab?.group?.mhab?.name

  const mHab = await client.identifiers().get(memberName)
  consoleLog(`Local member identifier : `, gHab)
  const members = await client.identifiers().members(groupName)
  consoleLog(`Group members : `, members)

  let aid = gHab['prefix']

  let nonce = randomNonce()
  let vcpRes = await client.registries().create({
    name: groupName,
    registryName: `${groupName}-reg`,
    nonce: nonce
  })
  consoleLog(`Create Registry result : `, vcpRes)
  let serder = vcpRes.regser
  let anc = vcpRes.serder
  let sigs = vcpRes.sigs

  let sigers = sigs.map((sig: any) => new signify.Siger({ qb64: sig }))

  let ims = signify.d(signify.messagize(anc, sigers))
  let atc = ims.substring(anc.size)
  let regbeds = {
    vcp: [serder, ''],
    anc: [anc, atc]
  }

  const recipients = members.signing
    .map((m: { aid: string }) => m.aid)
    .filter((aid: string) => aid !== mHab.prefix)

  if (recipients.length > 0) {
    consoleLog(
      `Sending join registry inception exn message to other members : `,
      recipients
    )
    await client
      .exchanges()
      .send(
        mHab.name,
        'registry',
        mHab,
        '/multisig/vcp',
        { gid: aid, usage: 'Issue vLEIs' },
        regbeds,
        recipients
      )
  }
  return vcpRes
}

export const joinMultisigRegistry = async (
  client: SignifyClient,
  exnMsgSaid: string,
  groupName: string
) => {
  consoleLog(`starting  joinMultisigRegistry...`)

  let res = await client.groups().getRequest(exnMsgSaid)
  consoleLog(`group exn request with said ${exnMsgSaid} `, res)
  let exn = res[0].exn
  let vcp = exn.e.vcp
  let nonce = vcp.n

  const gHab = await client.identifiers().get(groupName)
  consoleLog(`gHab : `, gHab)
  let memberName = gHab?.group?.mhab?.name
  const mHab = await client.identifiers().get(memberName)
  consoleLog(`mHab : `, mHab)
  const members = await client.identifiers().members(groupName)
  consoleLog(`gHab members : `, members)

  let aid = gHab['prefix']
  let vcpRes = await client.registries().create({
    name: groupName,
    registryName: `${groupName}-reg`,
    nonce: nonce
  })
  consoleLog('reg_nonce: ', nonce)

  consoleLog(`REG ICP result : `, vcpRes)

  let serder = vcpRes.regser
  let anc = vcpRes.serder
  let sigs = vcpRes.sigs

  let sigers = sigs.map((sig: any) => new signify.Siger({ qb64: sig }))

  let ims = signify.d(signify.messagize(anc, sigers))
  let atc = ims.substring(anc.size)
  let regbeds = {
    vcp: [serder, ''],
    anc: [anc, atc]
  }

  const recipients = members.signing
    .map((m: { aid: string }) => m.aid)
    .filter((aid: string) => aid !== mHab.prefix)

  consoleLog(
    `recipients for sending /multisig/vcp exn message  :: `,
    recipients
  )
  await client
    .exchanges()
    .send(
      mHab.name,
      'registry',
      mHab,
      '/multisig/vcp',
      { gid: aid, usage: 'Issue ACDC credential' },
      regbeds,
      recipients
    )
  consoleLog(`sent /multisig/vcp exn message!!`)

  return vcpRes
}

export async function multisigAddEndRole(
  client: SignifyClient,
  groupName: string,
  eid: string,
  gHab: any,
  mHab: any,
  members: any
) {
  consoleLog(`Initiating adding end role auth for multisig aid`, groupName)

  let stamp = new Date().toISOString().replace('Z', '000+00:00')

  let endRoleRes = await client
    .identifiers()
    .addEndRole(groupName, 'agent', eid, stamp)
  consoleLog(`Add-End-Role result: `, endRoleRes)

  let rpy = endRoleRes.serder
  let sigs = endRoleRes.sigs
  let ghabState = gHab['state']
  let sner = new CesrNumber({}, undefined, ghabState['ee']['s'])
  let seal = [
    'SealEvent',
    { i: gHab['prefix'], s: sner.num, d: ghabState['ee']['d'] }
  ]
  let sigers = sigs.map((sig: any) => new signify.Siger({ qb64: sig }))
  let roleims = signify.d(
    signify.messagize(rpy, sigers, seal, undefined, undefined, false)
  )
  let atc = roleims.substring(rpy.size)
  let roleembeds = {
    rpy: [rpy, atc]
  }

  const recipients = members
    .map((m: { aid: string }) => m.aid)
    .filter((aid: string) => aid !== mHab.prefix)

  if (recipients.length > 0) {
    consoleLog(
      `Sending join end role auth exn message to other members : `,
      recipients
    )

    await client
      .exchanges()
      .send(
        mHab.name,
        'multisig',
        mHab,
        '/multisig/rpy',
        { gid: gHab['prefix'] },
        roleembeds,
        recipients
      )
  }
  return endRoleRes
}

export const joinAddEndRole = async (
  client: SignifyClient,
  exnMsgSaid: string,
  groupName: string
) => {
  consoleLog(`starting join end role auth event...`)
  const gHab = await client.identifiers().get(groupName)

  let memberName = gHab?.group?.mhab?.name
  const mHab = await client.identifiers().get(memberName)
  const members = await client.identifiers().members(groupName)

  let res = await client.groups().getRequest(exnMsgSaid)
  consoleLog(`Group request with exn said ${exnMsgSaid} `, res)

  let exn = res[0].exn

  let rpystamp = exn.e.rpy.dt
  let rpyrole = exn.e.rpy.a.role
  let rpyeid = exn.e.rpy.a.eid

  let endRoleRes = await client
    .identifiers()
    .addEndRole(groupName, rpyrole, rpyeid, rpystamp)

  let rpy = endRoleRes.serder
  let sigs = endRoleRes.sigs
  let mstate = gHab['state']
  let sner = new CesrNumber({}, undefined, mstate['ee']['s'])
  let seal = [
    'SealEvent',
    { i: gHab['prefix'], s: sner.num, d: mstate['ee']['d'] }
  ]
  let sigers = sigs.map((sig: any) => new signify.Siger({ qb64: sig }))
  let roleims = signify.d(
    signify.messagize(rpy, sigers, seal, undefined, undefined, false)
  )
  let atc = roleims.substring(rpy.size)
  let roleembeds = {
    rpy: [rpy, atc]
  }

  const recipients = members.signing
    .map((m: { aid: string }) => m.aid)
    .filter((aid: string) => aid !== mHab.prefix)
  consoleLog(`Recipients for sending /multisig/rpy exn message : `, recipients)
  await client
    .exchanges()
    .send(
      mHab.name,
      'multisig',
      mHab,
      '/multisig/rpy',
      { gid: gHab['prefix'] },
      roleembeds,
      recipients
    )

  consoleLog(`Sent /multisig/rpy exn message.`)

  return endRoleRes
}

export const filterAuthSigningMembers = async (signingMembers: any[]) => {
  const members = signingMembers
    .map((m: { aid: string; ends: { agent: any; witness: any } }) => m)
    .filter((m: { aid: string; ends: { agent: any; witness: any } }) =>
      hasKey(m.ends, 'agent')
    )

  return members
}

export async function initiateMultisigAidRotation(
  client: SignifyClient,
  groupName: string,
  memberName: string,
  states: any,
  rstates: any
) {
  consoleLog(`Starting initiateMultisigAidRotation...`)
  const mHab = await client.identifiers().get(memberName)
  const members = await client.identifiers().members(groupName)

  let rotResult = await client.identifiers().rotate(groupName, {
    states: states,
    rstates: rstates
  })

  let serder = rotResult.serder
  let sigs = rotResult.sigs
  let sigers = sigs.map((sig: any) => new Siger({ qb64: sig }))
  let ims = signify.d(messagize(serder, sigers))
  let atc = ims.substring(serder.size)
  let embeds = {
    rot: [serder, atc]
  }

  let smids = states.map((state) => state['i'])

  const recipients = members.signing
    .map((m: { aid: string }) => m.aid)
    .filter((aid: string) => aid !== mHab.prefix)

  await client
    .exchanges()
    .send(
      mHab.name,
      'multisig',
      mHab,
      '/multisig/rot',
      { gid: serder.pre, smids: smids, rmids: smids },
      embeds,
      recipients
    )
  console.log(`${mHab.name} sent multisig/rot exchange message...`)

  return rotResult
}

export async function joinMultisigAidRotation(
  client: SignifyClient,
  notification: Notification
): Promise<signify.EventResult> {
  consoleLog(
    `starting joinMultisigAidRotation for exn message said : `,
    notification.a.d
  )

  let res = await client.groups().getRequest(notification.a.d)
  consoleLog(`group exn request : `, res)
  let exn = res[0].exn

  const mHab = await client.identifiers().get(res[0].memberName)
  consoleLog(`mHab :: `, mHab)

  let states = []
  let sortedParticipants = exn.a.smids.sort((a, b) =>
    a < b ? -1 : b > a ? 1 : 0
  )
  for (let i = 0; i < sortedParticipants.length; i++) {
    let state = await getKeyState(client, sortedParticipants[i])
    states.push(state[0])
  }
  consoleLog(`sorted states : `, states)

  let rotResult = await client.identifiers().rotate(res[0].groupName, {
    states: states,
    rstates: states
  })
  consoleLog(` rotation event created : `, rotResult)

  let serder = rotResult.serder
  let sigs = rotResult.sigs
  let sigers = sigs.map((sig: any) => new Siger({ qb64: sig }))
  let ims = signify.d(messagize(serder, sigers))
  let atc = ims.substring(serder.size)
  let embeds = {
    rot: [serder, atc]
  }

  let smids = exn.a.smids
  let recp = []
  for (let indx = 0; indx < states.length; indx++) {
    if (states[indx].i === mHab.prefix) {
      continue
    }
    recp.push(states[indx]['i'])
  }

  await client
    .exchanges()
    .send(
      mHab.name,
      'multisig',
      mHab,
      '/multisig/rot',
      { gid: serder.pre, smids: smids, rmids: smids },
      embeds,
      recp
    )
  console.log(`${mHab.name} sent multisig/rot exchange message...`)

  return rotResult
}

export async function joinAsMultisigAidMemberUsingRotation(
  client: SignifyClient,
  notification: Notification,
  groupName: string
): Promise<any> {
  consoleLog(
    `starting joinAsMultisigAidMemberUsingRotation for exn message said : `,
    notification.a.d
  )

  let exnRes = await client.exchanges().get(notification.a.d)
  consoleLog(`exn message : `, exnRes)

  let exn = exnRes.exn
  let smids = exn.a.smids
  let rmids = exn.a.rmids
  let gid = exn.a.gid

  consoleLog(`smids  : `, smids)
  let localAid = await findLocalAidFromSmids(client, smids)
  consoleLog(`local member aid from smids  : `, localAid)
  if (!localAid) throw new Error('Local AID is not part of signing members')

  const recipients: string[] = [...new Set([...smids, ...(rmids || [])])]
  consoleLog(`distinct recipient from smids and rmids  : `, recipients)

  const localAidIndex = recipients.indexOf(localAid.prefix)
  if (localAidIndex !== -1) {
    recipients.splice(localAidIndex, 1)
  }
  consoleLog(`recipient after removing local member aid  : `, recipients)

  const idx = smids.indexOf(localAid.prefix)
  if (idx === -1) {
    consoleLog(`The local aid ${localAid.prefix} was not found in smids.`)
  }

  const odx = rmids.indexOf(localAid.prefix)
  if (odx === -1) {
    console.log(`The local aid ${localAid.prefix} was not found in rmids.`)
  }

  let embedEvnt = exn.e
  let ked = embedEvnt.rot
  const rot = new Serder(ked)

  const keeper = client.manager!.get(localAid)
  const sigs = await keeper.sign(signify.b(rot.raw), true, [idx], [odx])

  let op = await client.groups().join(groupName, rot, sigs, gid, smids, rmids)

  let sigers = sigs.map((sig: any) => new Siger({ qb64: sig }))
  let ims = signify.d(messagize(rot, sigers))
  let atc = ims.substring(rot.size)
  let embeds = {
    rot: [rot, atc]
  }

  await client
    .exchanges()
    .send(
      localAid.name,
      'multisig',
      localAid,
      '/multisig/rot',
      { gid: rot.pre, smids: smids, rmids: smids },
      embeds,
      recipients
    )
  consoleLog(`${localAid.name} sent multisig/rot exchange message...`)
  return op
  //return null
}

export const getGroupMembersSigningThreshold = async (
  client: SignifyClient,
  alias: string
): Promise<GroupMember[]> => {
  try {
    let aid = await client.identifiers().get(alias)
    if (hasKey(aid, 'group')) {
      let signingThreshold = aid?.state?.kt
      let minSigners = findMinimumSignersThreshold(signingThreshold)
      let members = await client.identifiers().members(alias)

      let groupMembers: GroupMember[] = members?.signing?.map(
        (item, index) =>
          ({
            aid: item.aid,
            ends: item.ends,
            weightage: Array.isArray(signingThreshold)
              ? signingThreshold[index]
              : null,
            weighted: Array.isArray(signingThreshold),
            minSignersThreshold: minSigners
          } as GroupMember)
      )

      groupMembers.sort((a, b) => a.aid.localeCompare(b.aid))
      return groupMembers
    } else return null
  } catch (ex) {
    console.error('ERROR_IN_getGroupMembersSigningThreshold : ', ex)
    return null
  }
}

/**
 * Calculates the minimum threshold from a given current signing threshold(kt), which can be either a single string or an array of strings and numbers.
 * The function determines the smallest set of elements from the array whose sum is equal to or exceeds 1.
 *
 * @param {string | (string | number)[]} kt - current signing threshold(kt), it can be a number, or an array of fractions.
 * @returns {number} The minimum number of members of group AID that must sign.
 *
 * Example Usage:
 * findMinSignersThreshold("2"); // returns 2
 * findMinSignersThreshold(["1/2", "1/4", "1", "3"]); // returns 1
 * findMinSignersThreshold(["1/3", "1/3", "1/3", "1/3"]); // returns 3
 */
export function findMinimumSignersThreshold(
  kt: string | (string | number)[]
): number {
  try {
    if (typeof kt === 'string') {
      return Number(kt)
    }

    const numericalFractions = kt.map((fraction) => {
      if (typeof fraction === 'number') {
        return fraction
      } else if (typeof fraction === 'string' && fraction.includes('/')) {
        const [numerator, denominator] = fraction.split('/').map(Number)
        return numerator / denominator
      } else {
        return Number(fraction)
      }
    })

    numericalFractions.sort((a, b) => b - a)

    let sum = 0
    let threshold = 0

    for (const fraction of numericalFractions) {
      sum += fraction
      threshold++
      if (sum >= 1) {
        break
      }
    }

    return threshold
  } catch (x) {
    return -1
  }
}
