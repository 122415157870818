import React, { forwardRef, Ref } from 'react'
import Error from '@/components/typography/Error'
import { Label, TextInput as TextInputFB, TextInputProps } from 'flowbite-react'

interface IProps extends TextInputProps {
  id: string
  type?: string
  error?: React.ReactNode | string
  label?: string
  hint?: React.ReactNode
  placeholder?: string
  className?: string
  extraClassName?: string
  containerClassName?: string
  labelClassName?: string
  autoComplete?: string
  rightIcon?: () => JSX.Element
}

const getHelperText = (
  error?: React.ReactNode | string,
  hint?: React.ReactNode
) => {
  if (error) {
    return <Error>{error}</Error>
  }
  if (hint) {
    return hint
  }
  return <></>
}

const TextInput = forwardRef(
  (
    {
      id,
      error,
      label,
      placeholder,
      containerClassName = '',
      labelClassName = '',
      type = 'text',
      rightIcon,
      autoComplete,
      hint,
      className = '',

      ...props
    }: IProps,
    ref: Ref<any>
  ) => {
    return (
      <div className={containerClassName}>
        <div className="mb-1">
          <Label
            htmlFor={id}
            className={labelClassName}
            value={label}
            color={error && 'failure'}
          />
        </div>
        <TextInputFB
          id={id}
          type={type}
          placeholder={placeholder}
          color={error ? 'failure' : 'gray'}
          helperText={getHelperText(error, hint)}
          rightIcon={rightIcon}
          ref={ref}
          {...props}
        />
      </div>
    )
  }
)
TextInput.displayName = 'TextInput'

export default TextInput
