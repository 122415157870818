import {
  ErrorContext,
  Middleware,
  OrgControllerApi,
  OrgResearchControllerApi,
  OrgTypeControllerApi,
  SearchControllerApi
} from '@/api/origin-org-svc'

import {
  UserControllerApi,
  Configuration,
  RequestContext,
  OorTitleControllerApi,
  UserRoleControllerApi,
  OnboardControllerApi
} from '@/api/origin-user-mgmt-svc'
import { getJWTToken } from '@/utils/auth'
import constants from '@/config/constants'
import useStore from '../state/store'
import { CustomError } from './CustomException'
import {
  CommonWorkflowControllerApi,
  EcrPrerequisitesControllerApi,
  OorPrerequisitesControllerApi,
  UtilControllerApi,
  VleiPrerequisitesControllerApi,
  WorkflowInstanceControllerApi
} from '@/api/origin-workflow-svc'
import { L10nControllerApi } from '@/api/origin-l10n-svc'
import { UiNotificationControllerApi } from '@/api/origin-notification-svc'
import { IdVerificationPhotoControllerApi } from '@/api/origin-id-verification-svc'
import { AIDApi, MultisigMemberApi } from '@/api/origin-agent-svc'

export const authMiddleware: Middleware = {
  pre: async (context: RequestContext): Promise<void> => {
    const token = getJWTToken()

    // Ensure headers object exists in the init object
    if (!context.init.headers) {
      context.init.headers = new Headers()
    }

    // TypeScript might complain if headers is still typed as HeadersInit,
    // which does not have a set method. So, cast it to Headers if necessary.
    const headers: Headers = new Headers(context.init.headers)
    headers.set('Authorization', token)
    headers.set('Content-Type', 'application/json')

    // Assign the modified headers back to the init object
    context.init.headers = headers
  },
  onError: (context: ErrorContext): Promise<Response | void> => {
    throw context.error
  }
}

async function customFetch(input: any, init?: any): Promise<any> {
  try {
    const response = await fetch(input, init)

    if (response.ok) {
      return response
    }

    const bodyError = await response.json()
    return handleErrorResponse(response, bodyError)
  } catch (exception) {
    throw exception
  }
}

function handleErrorResponse(response: any, bodyError) {
  let error: CustomError

  if (response.status === 401) {
    console.error(`HTTP 401 Unauthorized, redirecting to signin page`)
    useStore.getState().killSession()
    useStore.getState().clearSignify()

    error = new CustomError({
      title: 'ERROR-401',
      type: bodyError.type || '',
      instance: bodyError.instance || '',
      code: 'UNAUTHORIZED',
      detail: 'Unauthorized access detected.'
    })
  } else {
    error = new CustomError({
      code: bodyError.code || constants.ERROR_CODES.UNKNOWN,
      title: bodyError.title || '',
      type: bodyError.type || '',
      detail: bodyError.detail || '',
      instance: bodyError.instance || ''
    })
  }

  throw error
}

const apiConfig = new Configuration({
  middleware: [authMiddleware],
  fetchApi: customFetch,
  basePath: constants.PUBLIC_API_ENDPOINT
})

export const userService = new UserControllerApi(apiConfig)
export const ecrPrerequisitesService = new EcrPrerequisitesControllerApi(
  apiConfig as any
)
export const commonWorkflowService = new CommonWorkflowControllerApi(
  apiConfig as any
)
export const l10nService = new L10nControllerApi(apiConfig as any)
export const oorPrerequisitesService = new OorPrerequisitesControllerApi(
  apiConfig as any
)
export const oorTitleService = new OorTitleControllerApi(apiConfig as any)
export const uiNotificationService = new UiNotificationControllerApi(
  apiConfig as any
)
export const utilService = new UtilControllerApi(apiConfig as any)
export const vleiPrerequisitesService = new VleiPrerequisitesControllerApi(
  apiConfig as any
)
export const workflowInstanceService = new WorkflowInstanceControllerApi(
  apiConfig as any
)
export const orgTypeService = new OrgTypeControllerApi(apiConfig as any)
export const orgService = new OrgControllerApi(apiConfig as any)
export const orgResearchService = new OrgResearchControllerApi(apiConfig as any)
export const searchService = new SearchControllerApi(apiConfig as any)
export const userRoleService = new UserRoleControllerApi(apiConfig as any)
export const onboardService = new OnboardControllerApi(apiConfig as any)
export const idVerificationService = new IdVerificationPhotoControllerApi(
  apiConfig as any
)
export const agentMultisigService = new MultisigMemberApi(apiConfig as any)
export const agentAIDService = new AIDApi(apiConfig as any)
