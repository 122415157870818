/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UserEmailDto,
} from '../models/index';
import {
    UserEmailDtoFromJSON,
    UserEmailDtoToJSON,
} from '../models/index';

export interface Delete1Request {
    userId: string;
    value: string;
}

export interface GetUserEmailsRequest {
    userId: string;
}

export interface NewEmailRequest {
    userEmailDto: UserEmailDto;
}

/**
 * 
 */
export class EmailControllerApi extends runtime.BaseAPI {

    /**
     */
    async delete1Raw(requestParameters: Delete1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserEmailDto>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling delete1().'
            );
        }

        if (requestParameters['value'] == null) {
            throw new runtime.RequiredError(
                'value',
                'Required parameter "value" was null or undefined when calling delete1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/email/{user_id}/{value}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))).replace(`{${"value"}}`, encodeURIComponent(String(requestParameters['value']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserEmailDtoFromJSON(jsonValue));
    }

    /**
     */
    async delete1(requestParameters: Delete1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserEmailDto> {
        const response = await this.delete1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserEmailsRaw(requestParameters: GetUserEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserEmailDto>>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getUserEmails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/email/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserEmailDtoFromJSON));
    }

    /**
     */
    async getUserEmails(requestParameters: GetUserEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserEmailDto>> {
        const response = await this.getUserEmailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async newEmailRaw(requestParameters: NewEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserEmailDto>> {
        if (requestParameters['userEmailDto'] == null) {
            throw new runtime.RequiredError(
                'userEmailDto',
                'Required parameter "userEmailDto" was null or undefined when calling newEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/email/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserEmailDtoToJSON(requestParameters['userEmailDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserEmailDtoFromJSON(jsonValue));
    }

    /**
     */
    async newEmail(requestParameters: NewEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserEmailDto> {
        const response = await this.newEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
