/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JoinOrganizationConfirm
 */
export interface JoinOrganizationConfirm {
    /**
     * 
     * @type {string}
     * @memberof JoinOrganizationConfirm
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof JoinOrganizationConfirm
     */
    verificationCode: string;
}

/**
 * Check if a given object implements the JoinOrganizationConfirm interface.
 */
export function instanceOfJoinOrganizationConfirm(value: object): boolean {
    if (!('email' in value)) return false;
    if (!('verificationCode' in value)) return false;
    return true;
}

export function JoinOrganizationConfirmFromJSON(json: any): JoinOrganizationConfirm {
    return JoinOrganizationConfirmFromJSONTyped(json, false);
}

export function JoinOrganizationConfirmFromJSONTyped(json: any, ignoreDiscriminator: boolean): JoinOrganizationConfirm {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'verificationCode': json['verificationCode'],
    };
}

export function JoinOrganizationConfirmToJSON(value?: JoinOrganizationConfirm | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'verificationCode': value['verificationCode'],
    };
}

