/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrgDetailsDto } from './OrgDetailsDto';
import {
    OrgDetailsDtoFromJSON,
    OrgDetailsDtoFromJSONTyped,
    OrgDetailsDtoToJSON,
} from './OrgDetailsDto';

/**
 * 
 * @export
 * @interface OrgFillingDetailRequestDto
 */
export interface OrgFillingDetailRequestDto {
    /**
     * 
     * @type {string}
     * @memberof OrgFillingDetailRequestDto
     */
    workflowInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof OrgFillingDetailRequestDto
     */
    workflowInstanceAssignedTaskId: string;
    /**
     * 
     * @type {string}
     * @memberof OrgFillingDetailRequestDto
     */
    orgId: string;
    /**
     * 
     * @type {OrgDetailsDto}
     * @memberof OrgFillingDetailRequestDto
     */
    orgDetails?: OrgDetailsDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrgFillingDetailRequestDto
     */
    publicRecordLinks?: Array<string>;
}

/**
 * Check if a given object implements the OrgFillingDetailRequestDto interface.
 */
export function instanceOfOrgFillingDetailRequestDto(value: object): boolean {
    if (!('workflowInstanceId' in value)) return false;
    if (!('workflowInstanceAssignedTaskId' in value)) return false;
    if (!('orgId' in value)) return false;
    return true;
}

export function OrgFillingDetailRequestDtoFromJSON(json: any): OrgFillingDetailRequestDto {
    return OrgFillingDetailRequestDtoFromJSONTyped(json, false);
}

export function OrgFillingDetailRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrgFillingDetailRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'],
        'workflowInstanceAssignedTaskId': json['workflowInstanceAssignedTaskId'],
        'orgId': json['orgId'],
        'orgDetails': json['orgDetails'] == null ? undefined : OrgDetailsDtoFromJSON(json['orgDetails']),
        'publicRecordLinks': json['publicRecordLinks'] == null ? undefined : json['publicRecordLinks'],
    };
}

export function OrgFillingDetailRequestDtoToJSON(value?: OrgFillingDetailRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'workflowInstanceAssignedTaskId': value['workflowInstanceAssignedTaskId'],
        'orgId': value['orgId'],
        'orgDetails': OrgDetailsDtoToJSON(value['orgDetails']),
        'publicRecordLinks': value['publicRecordLinks'],
    };
}

