/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface FindByUserIdAndWorkflowIdRequest {
    workflow: string;
    user?: string;
}

/**
 * 
 */
export class WorkflowTaskActorControllerApi extends runtime.BaseAPI {

    /**
     * Returns list of entities by user-id and workflow
     */
    async findByUserIdAndWorkflowIdRaw(requestParameters: FindByUserIdAndWorkflowIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['workflow'] == null) {
            throw new runtime.RequiredError(
                'workflow',
                'Required parameter "workflow" was null or undefined when calling findByUserIdAndWorkflowId().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['user'] != null) {
            queryParameters['user'] = requestParameters['user'];
        }

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/task-actor/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns list of entities by user-id and workflow
     */
    async findByUserIdAndWorkflowId(requestParameters: FindByUserIdAndWorkflowIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.findByUserIdAndWorkflowIdRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

}
